import { PaymentProcessors } from '../typings/terminalSettings';

interface IPaymentProcessors {
  [index: string]: PaymentProcessors;
}

const PAYMENT_PROCESSORS: IPaymentProcessors = {
  clover: {
    label: 'Clover',
    value: 'clover',
  },
  cardConnect: {
    label: 'CardConnect',
    value: 'cardConnect',
  },
};

export default PAYMENT_PROCESSORS;
