import React from 'react';
import { ActivityGuestType } from '../../../store/Customer/types/assignActivities';
import Button from '../../Button';
import Modal from '../../Modal';
import styles from './styles.module.scss';

const AssignActivitiesModal = ({ open, toggle, message }: PropsTypes) => {
  return (
    <Modal isOpen={open}>
      <div className={styles.container}>
        <h2 className={styles.title}>Error Occurred </h2>
        <div className={styles.description}>
          <p>{message}</p>
        </div>
        <div className={styles.footer}>
          <Button
            theme="red"
            customClass={styles.button}
            handleClick={() => {
              toggle();
            }}
          >
            return
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignActivitiesModal;

interface PropsTypes {
  open: boolean;
  toggle: () => void;
  message: string;
}
