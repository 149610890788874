import { EVENTS } from '../actions';

export const initialState = {
  data: null,
  selectedEvent: null,
  errorMessage: '',
  eventExists: false,
};

export type InitialStateType = typeof initialState;

export default function newCustomerReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case EVENTS.GET_CURRENT_DAY_EVENTS_SUCCESSFULLY: {
      return {
        data: payload.data,
        selectedEvent: null,
        errorMessage: '',
        eventExists: false,
      };
    }
    case EVENTS.GET_CURRENT_DAY_EVENTS_FAILURE: {
      return {
        data: null,
        selectedEvent: null,
        errorMessage: payload.message,
        eventExists: false,
      };
    }
    case EVENTS.SELECT_EVENT: {
      return {
        ...state,
        selectedEvent: payload,
        eventExists: true,
      };
    }
    case EVENTS.RESET_EVENT: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
