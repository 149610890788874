import React, { useState, Fragment } from 'react';
import cn from 'classnames';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import instance from '../../services/Api';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import TextField from '../../components/TextField';
import Keyboard from '../../components/Keyboard';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import WarningAlert from '../../components/WarningAlert';
import { useSelector } from 'react-redux';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

const SettingsApiScreen = () => {
  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);

  const [activeField, setActiveField] = useState('userName');
  const [connectionError, setConnectionError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldsOfForm, setFieldsOfForm] = useState<any>({
    userName: localStorage.getItem('api_user_name') ? localStorage.getItem('api_user_name') : '',
    password: localStorage.getItem('api_password') ? localStorage.getItem('api_password') : '',
    serverAPI: localStorage.getItem('api_server') ? localStorage.getItem('api_server') : '',
  });

  const onKeyboardPress = (newFieldValue: string, fieldName) => {
    setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
  };

  const onChangeInputValue = (e: any) => {
    setFieldsOfForm({ ...fieldsOfForm, [activeField]: e.target.value });
  };

  const checkNewSettings = async () => {
    try {
      const { data } = await instance.post('auth', {
        url: `https://${fieldsOfForm.serverAPI}.com/CoreAPI/`,
        username: fieldsOfForm.userName,
        password: fieldsOfForm.password,
      });
      localStorage.setItem('api_user_name', fieldsOfForm.userName);
      localStorage.setItem('api_password', fieldsOfForm.password);
      localStorage.setItem('api_server', fieldsOfForm.serverAPI);
      localStorage.setItem('auth_token', data.token);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  };

  const saveSettings = async () => {
    try {
      setLoading(true);
      const { token } = await checkNewSettings();
      setLoading(false);
      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      history.replace(ROUTES.SETTINGS_KIOSK);
      // window.location.reload();
    } catch (e) {
      setLoading(false);
      setConnectionError(true);
    }
  };

  return (
    <Fragment>
      <ContentContainer loading={loading} theme="red">
        <HeaderPanel theme="dark" shadow />
        <div className={styles.contentInner}>
          <div className={styles.contentColumn}>
            <h1 className={styles.title}>API Connectivity</h1>
            <div className={styles.spaceBetweenRow}>
              <div className={styles.fieldLabel}>UserName</div>
              <TextField
                type="text"
                readOnly={!nativeKeyboardState}
                value={fieldsOfForm.userName}
                placeholder="Username"
                isActive={activeField === 'userName'}
                invalid={false}
                errorMessage={''}
                theme="themeTransparent"
                onChange={onChangeInputValue}
                onFocus={() => setActiveField('userName')}
                customClass={styles.smallField}
              />
            </div>
            <div className={styles.spaceBetweenRow}>
              <div className={styles.fieldLabel}>Password</div>
              <TextField
                readOnly={!nativeKeyboardState}
                type="password"
                value={fieldsOfForm.password}
                placeholder="Password"
                isActive={activeField === 'password'}
                invalid={false}
                errorMessage={''}
                theme="themeTransparent"
                onChange={onChangeInputValue}
                onFocus={() => setActiveField('password')}
                customClass={styles.smallField}
              />
            </div>
            <div>
              <div className={styles.fieldLabel}>Core API Base URL</div>
              <div className={styles.fieldWithDescription}>
                <div className={cn(styles.fieldDescription, styles.fieldDescriptionLeft)}>
                  <span>http://</span>
                </div>
                <div>
                  <TextField
                    type="text"
                    readOnly={!nativeKeyboardState}
                    value={fieldsOfForm.serverAPI}
                    placeholder="Enter Track URL"
                    isActive={activeField === 'serverAPI'}
                    invalid={false}
                    errorMessage={''}
                    theme="themeTransparent"
                    onChange={onChangeInputValue}
                    onFocus={() => setActiveField('serverAPI')}
                    customClass={styles.smallField}
                  />
                </div>
                <div className={cn(styles.fieldDescription, styles.fieldDescriptionRight)}>
                  <span>.com</span>
                </div>
              </div>
            </div>
            <div />
          </div>
        </div>
        {!nativeKeyboardState && (
          <div className={styles.keyboard}>
            <Keyboard
              fieldName={activeField}
              valueOfField={fieldsOfForm[activeField]}
              onChange={onKeyboardPress}
              hideKeyboard={() => {}}
              handleEnter={() => {}}
            />
          </div>
        )}
        <div className={styles.navigation}>
          <Button theme="white" handleClick={() => history.replace(ROUTES.ROOT)} customClass={styles.cancelButton}>
            Cancel
          </Button>
          <Button theme="red" handleClick={saveSettings} customClass={styles.saveButton}>
            Continue
          </Button>
        </div>
      </ContentContainer>
      <WarningAlert
        isOpen={connectionError}
        title="Whoops!"
        description="These settings are invalid. Please try again"
        handleClick={() => setConnectionError(false)}
      />
    </Fragment>
  );
};

export default SettingsApiScreen;
