import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../constants';

import WelcomeScreen from './WelcomeScreen';
import ModeSelectionScreen from './ModeSelectionScreen';

import LogInFirstStepScreen from './LogInFirstStepScreen';
import LogInSecondStepScreen from './LogInSecondStepScreen';

import LogInAnotherWayScreen from './LogInAnotherWayScreen';
import LogInAnotherWayEnterPhoneScreen from './LogInAnotherWayEnterPhoneScreen';
import LogInAnotherWayEnterPinScreen from './LogInAnotherWayEnterPinScreen';

import NewPinScreen from './NewPinScreen';
import ResetPinScreen from './ResetPinScreen';
import UpdatePinScreen from './UpdatePinScreen';

import SignUpFirstStepScreen from './SignUpFirstStepScreen';
import SignUpScreen from './SignUpScreen';
import NewGuestScreen from './NewGuestScreen';

import WaiverAgreementAdultScreen from './WaiverAgreementAdultScreen';
import WaiverAgreementMinorScreen from './WaiverAgreementMinorScreen';
import ReviewOfNewCustomersScreen from './ReviewOfNewCustomersScreen';

import EnforceWaiverAgreementAdultScreen from './EnforceWaiverAgreementAdultScreen';
import EnforceWaiverAgreementMinorScreen from './EnforceWaiverAgreementMinorScreen';

import LoginSettingsScreen from './LoginSettingsScreen';
import SettingsApiScreen from './SettingsApiScreen';
import TerminalSettingsScreen from './TerminalSettingsScreen';

import MainFoodModeScreen from './MainFoodModeScreen';
import MainScreen from './MainFoodModeScreen';
import MainRegistrationModeScreen from './MainRegistrationModeScreen';

import ProfileScreen from './ProfileScreen';
import EditProfileScreen from './EditProfileScreen';
import EditNewGuestScreen from './EditNewGuestScreen';
import CategoriesScreen from './CategoriesScreen';
import ProductsByCategoryScreen from './ProductsScreen';
import ProductScreen from './ProductScreen';

import PaymentOnlineScreen from './PaymentOnlineScreen';
import PaymentText2PayScreen from './PaymentText2PayScreen';

import PaymentCloverScreen from './PaymentCloverScreen';
import PaymentCloverProcessingScreen from './PaymentCloverProcessingScreen';

import PaymentStripeScreen from './PaymentStripeScreen';
import PaymentCardConnectScreen from './PaymentCardConnectScreen';
import PaymentSuccessScreen from './PaymentSuccessScreen';
import PaymentErrorScreen from './PaymentErrorScreen';
import PaymentFailureScreen from './PaymentFailureScreen';

import ProtectedRoute from '../services/ProtectedRoute';
import ReservationCheckIn from './ReservationCheckIn/ReservationCheckIn';
import EventsListScreen from './EventsListScreen';
import ConfirmationScreen from './ConfirmationScreen';
import CongratulationsScreen from './CongratulationsScreen';
import AssignActivities from './AsignActivities/AsignActivities';

const Routing: React.FC = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to={ROUTES.ROOT} />
    </Route>
    <ProtectedRoute path={ROUTES.ROOT} component={WelcomeScreen} />
    <ProtectedRoute path={ROUTES.LOG_IN_FIRST_STEP} component={LogInFirstStepScreen} />
    <ProtectedRoute path={ROUTES.LOG_IN_SECOND_STEP} component={LogInSecondStepScreen} />
    <ProtectedRoute path={ROUTES.RESERVATION_CHECK_IN} component={ReservationCheckIn} />
    <ProtectedRoute path={ROUTES.ASSIGN_ACTIVITIES} component={AssignActivities} />

    <ProtectedRoute path={ROUTES.LOG_IN_ANOTHER_WAY} component={LogInAnotherWayScreen} />
    <ProtectedRoute path={ROUTES.LOG_IN_ANOTHER_WAY_ENTER_PHONE} component={LogInAnotherWayEnterPhoneScreen} />
    <ProtectedRoute path={ROUTES.LOG_IN_ANOTHER_WAY_ENTER_PIN} component={LogInAnotherWayEnterPinScreen} />
    <ProtectedRoute path={ROUTES.RESET_PIN} component={ResetPinScreen} />
    <ProtectedRoute path={ROUTES.NEW_PIN} component={NewPinScreen} />
    <ProtectedRoute path={ROUTES.UPDATE_PIN} component={UpdatePinScreen} />

    <ProtectedRoute path={ROUTES.EVENT_LISTS} component={EventsListScreen} />
    <ProtectedRoute path={ROUTES.CONFIRMATION_CODE} component={ConfirmationScreen} />

    <ProtectedRoute path={ROUTES.SIGN_UP_FIRST_STEP} component={SignUpFirstStepScreen} />
    <ProtectedRoute path={ROUTES.SIGN_UP} component={SignUpScreen} />
    <ProtectedRoute path={ROUTES.NEW_GUEST} component={NewGuestScreen} />

    <ProtectedRoute path={`${ROUTES.WAIVER_AGREEMENT_ADULT}/:id`} component={WaiverAgreementAdultScreen} />
    <ProtectedRoute path={ROUTES.WAIVER_AGREEMENT_MINOR} component={WaiverAgreementMinorScreen} />

    <ProtectedRoute
      exact={true}
      path={`${ROUTES.ENFORCE_WAIVER_AGREEMENT_ADULT}/:id`}
      component={EnforceWaiverAgreementAdultScreen}
    />
    <ProtectedRoute
      exact={true}
      path={ROUTES.ENFORCE_WAIVER_AGREEMENT_MINOR}
      component={EnforceWaiverAgreementMinorScreen}
    />

    <ProtectedRoute exact={true} path={ROUTES.REVIEW_OF_NEW_CUSTOMERS} component={ReviewOfNewCustomersScreen} />

    <Route path={ROUTES.LOGIN_SETTINGS} component={LoginSettingsScreen} />
    <Route path={ROUTES.SETTINGS_API} component={SettingsApiScreen} />
    <ProtectedRoute path={ROUTES.SETTINGS_KIOSK} component={TerminalSettingsScreen} />

    <Route path={ROUTES.MODE_SELECTION} component={ModeSelectionScreen} />

    <ProtectedRoute path={ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE} component={MainFoodModeScreen} />
    <ProtectedRoute path={ROUTES.MAIN_REGISTRATION_MODE} component={MainRegistrationModeScreen} />

    <ProtectedRoute path={ROUTES.PROFILE} component={ProfileScreen} />
    <ProtectedRoute path={`${ROUTES.EDIT_PROFILE}/:custId`} component={EditProfileScreen} />
    <ProtectedRoute path={`${ROUTES.EDIT_NEW_GUEST}/:type/:guestId`} component={EditNewGuestScreen} />

    <Route exact={true} path={ROUTES.PRODUCTS} component={CategoriesScreen} />
    <Route exact={true} path={`${ROUTES.PRODUCTS}/:categoryId`} component={ProductsByCategoryScreen} />
    <Route exact={true} path={`${ROUTES.PRODUCTS}/:categoryId/:productId`} component={ProductScreen} />

    <ProtectedRoute path={ROUTES.PAYMENT_ONLINE} component={PaymentOnlineScreen} />

    <ProtectedRoute path={ROUTES.PAYMENT_CLOVER} component={PaymentCloverScreen} />
    <ProtectedRoute path={ROUTES.PAYMENT_CLOVER_PROCESSING} component={PaymentCloverProcessingScreen} />

    <ProtectedRoute path={ROUTES.PAYMENT_STRIPE} component={PaymentStripeScreen} />
    <ProtectedRoute path={ROUTES.PAYMENT_CARD_CONNECT} component={PaymentCardConnectScreen} />

    <ProtectedRoute path={ROUTES.TEXT_2_PAY} component={PaymentText2PayScreen} />
    <ProtectedRoute path={ROUTES.PAYMENT_SUCCESS} component={PaymentSuccessScreen} />
    <ProtectedRoute path={ROUTES.PAYMENT_ERROR} component={PaymentErrorScreen} />
    <ProtectedRoute path={ROUTES.PAYMENT_FAILURE} component={PaymentFailureScreen} />

    <ProtectedRoute path={ROUTES.CONGRATULATIONS} component={CongratulationsScreen} />
  </Switch>
);

export default Routing;
