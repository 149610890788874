import { fetchControlPanelSettings } from '../api';

export const generateAPIServer = () => {
  const API_SERVER = localStorage.getItem('api_server');

  return API_SERVER.includes('.') ? API_SERVER.split('.')[0] : API_SERVER;
};

export const generateQRCode = async (paymentLinkId: string) => {
  const { data } = await fetchControlPanelSettings('PaymentLinkUrl', 'MainEngine');

  const payByLinkData = {
    paymentLinkId,
    coreApiServer: generateAPIServer(),
  };

  return `${data['0'].settingValue}/${btoa(JSON.stringify(payByLinkData))}`;
};
