import { ActivityType } from "../types/assignActivities";

export const ASSIGN_ACTIVITIES_TYPES = {
    GET_ACTIVITIES_BY_CONFIRMATION_CODE_REQUEST: 'customer/assignActivities/GET_ACTIVITIES_BY_CONFIRMATION_CODE_REQUEST',
    GET_ACTIVITIES_BY_CONFIRMATION_CODE_SUCCESS: 'customer/assignActivities/GET_ACTIVITIES_BY_CONFIRMATION_CODE_SUCCESS',
    GET_ACTIVITIES_BY_CONFIRMATION_CODE_FAILURE: 'customer/assignActivities/GET_ACTIVITIES_BY_CONFIRMATION_CODE_FAILURE',
    GET_ACTIVITIES_BY_CONFIRMATION_CODE_RESET: 'customer/assignActivities/GET_ACTIVITIES_BY_CONFIRMATION_CODE_RESET',
};

export const getActivitiesByConfirmationCodeAction = (payload: string) => ({
    type: ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_REQUEST,
    payload,
  });
  
  export const getActivitiesByConfirmationCodeSuccess = (payload: ActivityType[]) => ({
    type: ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_SUCCESS,
    payload,
  });
  
  export const getActivitiesByConfirmationCodeFailure = () => ({
    type: ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_FAILURE,
  });
  
  export const getActivitiesByConfirmationCodeReset= () => ({
      type: ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_RESET,
  });
  