import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getActivityListByConfirmationNumber } from '../../../api';


import { ASSIGN_ACTIVITIES_TYPES } from '../actions';
import { getActivitiesByConfirmationCodeFailure, getActivitiesByConfirmationCodeSuccess } from '../actions/assignActivitiesActions';

export function* getActivitiesByConfirmationCodeSagaWorker({ payload }: {payload: string, type: string}) {
  try {
    const {data}: {data: any} = yield call(getActivityListByConfirmationNumber, payload);
    yield put(getActivitiesByConfirmationCodeSuccess({...data}))
  } catch (err) {
    yield put(getActivitiesByConfirmationCodeFailure())
  }
}

export default function* assignActivitiesSaga() {
  yield all([yield takeLatest(ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_REQUEST, getActivitiesByConfirmationCodeSagaWorker)]);
}
