import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import ContentContainer from '../../components/ContentContainer';

import styles from './styles.module.scss';
import HeaderPanel from '../../components/HeaderPanel';
import { endCustomerSessionAction } from '../../store/General/actions';
import { makeText2PayAction } from '../../store/Payment/actions';
import QrCode from '../../components/QrCode/QrCode';
import IdleMonitor from '../../services/IdleMonitor';
import { generateQRCode } from '../../utils/url';

const PaymentText2PayScreen = () => {
  const dispatch = useDispatch();

  const {
    payByLink: { paymentLinkId, loading },
  } = useSelector(state => state);

  const [QRCodeLink, setQRCodeLink] = useState('');

  useEffect(() => {
    const getQRCodeLink = async () => {
      const link = await generateQRCode(paymentLinkId);
      setQRCodeLink(link);
    };

    if (!loading) {
      getQRCodeLink();
    }
  }, [loading]);

  React.useEffect(() => {
    dispatch(makeText2PayAction());
  }, []);

  const endCustomerSession = () => {
    dispatch(endCustomerSessionAction());
  };

  return (
    <>
      <ContentContainer theme="red" loading={loading}>
        <HeaderPanel theme="dark" />
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>Thank you!</h1>
          <h3 className={styles.description}>
            We’ve sent you a text message with a link to complete your purchase! If you did not receive a text message,
            <br />
            then scan the QR code below to complete your payment!
          </h3>
          <div className={styles.paymentQrCode}>
            {paymentLinkId && QRCodeLink ? (
              <div className={styles.qr}>
                <QrCode text={QRCodeLink} />
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.footerContainer}>
          {/*<span className={styles.message}>You’re all set you’ve completed your payment!</span>*/}
          <Button handleClick={endCustomerSession} theme="white" customClass={styles.okBtn}>
            Finish
          </Button>
        </div>
      </ContentContainer>
      <IdleMonitor removeLineItems={false} />
    </>
  );
};

export default PaymentText2PayScreen;
