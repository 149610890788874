import { AxiosInstance, AxiosResponse } from 'axios';

const updateTokenInterceptor = (response: AxiosResponse, instance: AxiosInstance) => {
  if (response.data.token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.withCredentials = true;
  }

  if (response.data.clubspeedData !== undefined) {
    response.data = response.data.clubspeedData;
  }

  return response;
};

export default updateTokenInterceptor;
