export const START_CUSTOMER_SESSION = 'terminal/START_CUSTOMER_SESSION';
export const START_CUSTOMER_SESSION_FOR_FUN = 'terminal/START_CUSTOMER_SESSION_FOR_FUN';
export const START_CUSTOMER_SESSION_FOR_FOOD = 'terminal/START_CUSTOMER_SESSION_FOR_FOOD';
export const START_CUSTOMER_SESSION_FOR_RESERVATION_CHECK_IN =
  'terminal/START_CUSTOMER_SESSION_FOR_RESERVATION_CHECK_IN';

export const startCustomerSessionAction = payload => ({
  type: START_CUSTOMER_SESSION,
  payload,
});

export const startCustomerSessionForFunAction = payload => ({
  type: START_CUSTOMER_SESSION_FOR_FUN,
  payload,
});

export const startCustomerSessionForFoodAction = payload => ({
  type: START_CUSTOMER_SESSION_FOR_FOOD,
  payload,
});

export const startCustomerSessionForReservationCheckInAction = payload => ({
  type: START_CUSTOMER_SESSION_FOR_RESERVATION_CHECK_IN,
  payload,
});
