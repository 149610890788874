import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import Routing from './screens/Routing';
import { createBrowserHistory } from 'history';
import { store, persistor } from './store';
import './App.scss';

const history = createBrowserHistory();
export { history };

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Routing />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
