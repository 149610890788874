import { takeLatest, all, call, put } from 'redux-saga/effects';
import { CONVENIENCE_FEES, getConvenienceFeesSuccess, getConvenienceFeesFailure } from '../actions';
import { fetchTaxes } from '../../../api';

export function* convenienceFeesWorker() {
  try {
    const {
      data: {
        ClubKioskEnableClubSpeedConvenienceFee,
        ClubKioskClubSpeedConvenienceFeeType,
        ClubKioskClubSpeedConvenienceFeeAmount,
        ClubKioskClubSpeedConvenienceFeeMinimum,
        ClubKioskClubSpeedConvenienceFeeMaximum,
      },
    } = yield call<any>(fetchTaxes);

    yield put<any>(
      getConvenienceFeesSuccess({
        ClubKioskEnableClubSpeedConvenienceFee: ClubKioskEnableClubSpeedConvenienceFee[0]?.settingValue || false,
        ClubKioskClubSpeedConvenienceFeeType: ClubKioskClubSpeedConvenienceFeeType[0]?.settingValue || 1,
        ClubKioskClubSpeedConvenienceFeeAmount: ClubKioskClubSpeedConvenienceFeeAmount[0]?.settingValue || 3,
        ClubKioskClubSpeedConvenienceFeeMinimum: ClubKioskClubSpeedConvenienceFeeMinimum[0]?.settingValue || 3,
        ClubKioskClubSpeedConvenienceFeeMaximum: ClubKioskClubSpeedConvenienceFeeMaximum[0]?.settingValue || 3,
      }),
    );
  } catch (err) {
    yield put<any>(getConvenienceFeesFailure());
  }
}

export default function* startPaymentProcessSaga() {
  yield all([yield takeLatest(CONVENIENCE_FEES.REQUEST, convenienceFeesWorker)]);
}
