import _ from 'lodash';

const detectLabelField = (fields, field) => {
  if (!_.find(fields, obj => obj.customerFieldName === field)) {
    return '';
  }
  const value = _.find(fields, obj => obj.customerFieldName === field).displayName;
  return value;
};

export default detectLabelField;
