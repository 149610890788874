import { ASSIGN_ACTIVITIES_TYPES } from '../actions';
import { AssignActivitiesInitialState } from '../types/assignActivities';

export const initialState: AssignActivitiesInitialState = {
    activities: null
}

export default function assignActivitiesReducer(state = initialState, { type, payload }: any): AssignActivitiesInitialState {
  switch (type) {
    case ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_SUCCESS:
        return {
            ...state,
            activities:  {
                ...payload,
            },
        };
    case ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_FAILURE:
        return {
            ...state,
            activities: {
                data: null,
            }
        };
    case ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_RESET:
        return {
            ...state,
            activities: null
        }
    default:
      return state;
  }
}
