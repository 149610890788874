import { CUSTOMER_TYPES, LOG_IN_ANOTHER_WAY_TYPES, NEW_CUSTOMER_TYPES } from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  custId: 0,
  company: '',
  fName: '',
  lName: '',
  racerName: '',
  birthDate: '',
  ignoreDOB: false,
  gender: 0,
  emailAddress: '',
  sourceId: 0,
  hotel: '',
  industryId: 0,
  refId: 0,
  doNotMail: false,
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  phoneNumber: '',
  phoneNumber2: '',
  cell: '',
  fax: '',
  licenseNumber: '',
  issuedBy: '',
  waiver: 0,
  waiver2: 0,
  crdId: '',
  rpm: 0,
  accountCreated: '',
  lastVisited: '',
  totalVisits: 0,
  totalRaces: 0,
  membershipStatus: 0,
  membershipText: '',
  memberShipTextLong: '',
  priceLevel: 0,
  promotionCode: '',
  isGiftCard: false,
  webUserName: '',
  password: '',
  award1: 0,
  award2: 0,
  custom1: '',
  custom2: '',
  custom3: '',
  custom4: '',
  privacy1: false,
  privacy2: false,
  privacy3: false,
  privacy4: false,
  status1: 0,
  status2: 0,
  status3: 0,
  status4: 0,
  deleted: false,
  isEmployee: false,
  originalId: 0,
  creditLimit: 0,
  creditOnHold: false,
  lastUnSubscribedDate: '',
  generalNotes: '',
  hash: '',
  consumingAlcohol: false,
  isRestricted: false,
  isGdprDeleted: false,
  pin: '',
  pinExists: false,
  pinError: '',
  loading: false,
};

export type InitialStateType = typeof initialState;

export default function customerReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case CUSTOMER_TYPES.SAVE_CUSTOMER_TO_STORE:
      return {
        ...state,
        ...payload,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.ADD_PHONE_AND_PIN:
      return {
        ...state,
        ...payload,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.UPDATE_BIRTH_DATE:
      return {
        ...state,
        birthDate: payload
      };
    case LOG_IN_ANOTHER_WAY_TYPES.ADD_PIN:
      return {
        ...state,
        ...payload,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.ENTER_PIN:
      return {
        ...state,
        ...payload,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PHONE_REQUEST:
      return {
        ...state,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PHONE_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PHONE_FAILURE:
      return {
        ...state,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PIN_REQUEST:
      return {
        ...state,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PIN_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PIN_FAILURE:
      return {
        ...state,
      };
    case NEW_CUSTOMER_TYPES.CREATE_NEW_CUSTOMER_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
