import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { verifyActivityByConfrimationNumber, verifyEventByConfrimationNumber } from '../../../api';

import {
  confirmationCodeCheckFailure,
  confirmationCodeCheckSuccess,
  CONFIRMATION_CODE_TYPES,
  confirmationCodeSuccessForBoth,
} from '../actions';
import {
  ConfirmationCodeCheckResponse,
  ConfirmationCodeCheckPayload,
  ChosenConfirmationCodePayload,
} from '../types/confirmationCode';

export function* verifyActitivityByConfirmationCodeSaga({
  payload,
}: {
  payload: ConfirmationCodeCheckPayload;
  type: string;
}) {
  try {
    const { data }: { data: ConfirmationCodeCheckResponse } = yield call(verifyActivityByConfrimationNumber, payload);
    yield put(
      data.success
        ? confirmationCodeCheckSuccess({
            ...data,
            confirmationNumber: payload,
          })
        : confirmationCodeCheckFailure({
            ...data,
          }),
    );
  } catch (err) {
    console.log(err);
  }
}

export function* verifyEventByConfirmationCodeSaga({
  payload,
}: {
  payload: ConfirmationCodeCheckPayload;
  type: string;
}) {
  try {
    const { data }: { data: ConfirmationCodeCheckResponse } = yield call(verifyEventByConfrimationNumber, payload);
    yield put(
      data.success
        ? confirmationCodeCheckSuccess({
            ...data,
            confirmationNumber: payload,
          })
        : confirmationCodeCheckFailure({
            ...data,
          }),
    );
  } catch (err) {
    console.log(err);
  }
}

export function* verifyConfirmationCodeForEventAndActivitySaga({
  payload,
}: {
  payload: ConfirmationCodeCheckPayload;
  type: string;
}) {
  try {
    const { data: activityData }: { data: ConfirmationCodeCheckResponse } = yield call(
      verifyActivityByConfrimationNumber,
      payload,
    );
    const { data: eventData }: { data: ConfirmationCodeCheckResponse } = yield call(
      verifyEventByConfrimationNumber,
      payload,
    );

    if (activityData.success && eventData.success) {
      yield put(
        confirmationCodeCheckSuccess({
          ...eventData,
          confirmationNumber: payload,
          isValidForBoth: true,
        }),
      );
    }

    if (activityData.success && !eventData.success) {
      yield put(
        confirmationCodeCheckSuccess({
          ...activityData,
          confirmationNumber: payload,
        }),
      );
    }

    if (!activityData.success && eventData.success) {
      yield put(
        confirmationCodeCheckSuccess({
          ...eventData,
          confirmationNumber: payload,
        }),
      );
    }

    if (!activityData.success && !eventData.success) {
      yield put(
        confirmationCodeCheckFailure({
          ...activityData,
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* confirmationCodeSuccessForBothSaga({
  payload,
}: {
  payload: ChosenConfirmationCodePayload;
  type: string;
}) {
  const { isEvent } = payload;
  const { confirmationCodeReducer } = yield select(store => store);

  if (isEvent) {
    yield put(
      confirmationCodeCheckSuccess({
        ...confirmationCodeReducer,
        isValidForBoth: false,
      }),
    );
  }

  if (!isEvent) {
    yield put(
      confirmationCodeCheckSuccess({
        ...confirmationCodeReducer,
        data: {
          custId: confirmationCodeReducer.data.custId,
          phoneNumber: confirmationCodeReducer.data.phoneNumber,
        },
        isValidForBoth: false,
      }),
    );
  }
}

export default function* newCustomerSaga() {
  yield all([
    yield takeLatest(
      CONFIRMATION_CODE_TYPES.VERIFY_ACTIVITY_BY_CONFIRMATION_CODE,
      verifyActitivityByConfirmationCodeSaga,
    ),
    yield takeLatest(CONFIRMATION_CODE_TYPES.VERIFY_EVENT_BY_CONFIRMATION_CODE, verifyEventByConfirmationCodeSaga),
    yield takeLatest(
      CONFIRMATION_CODE_TYPES.VERIFY_CONFIRMATION_CODE_FOR_EVENT_AND_ACTIVITY,
      verifyConfirmationCodeForEventAndActivitySaga,
    ),
    yield takeLatest(
      CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_SUCCESSFUL_FOR_EVENT_AND_ACTIVITY,
      confirmationCodeSuccessForBothSaga,
    ),
  ]);
}
