import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../App';
import { sha256 } from '../../utils/bcrypt';
import IdleMonitor from '../../services/IdleMonitor';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { logInAntherWayUpdatePinAction } from '../../store/Customer/actions';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import PhoneKeypad from '../../components/PhoneKeypad';
import cn from 'classnames';
import HeaderPanel from '../../components/HeaderPanel';
import ContentContainer from '../../components/ContentContainer';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import moment from 'moment';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';
import { addGuestInEventAction } from '../../store/Customer/actions/eventsActions';
import WarningAlert from '../../components/WarningAlert';

type formType = {
  [key: string]: string;
};

const LogInAnotherWayEnterPinScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { customerStore, confirmationCodeReducer, events, terminalSettings } = useSelector(state => state);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [activeField, setActiveField] = useState('pin');
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({
    pin: '',
    confirmPin: '',
    confirmationMode: 'false',
  });

  // Poo O 1992-05-12 buu@yahoo.com

  const [errorsOfForm, setErrorsOfForm] = useState({
    pin: '',
    confirmPin: '',
  });

  const validatePin = () => {
    if (fieldsOfForm.pin === '') {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Please enter a 4 digit pin' });
      return false;
    }
    if (fieldsOfForm.pin.length !== 4) {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Please enter a 4 digit pin' });
      return false;
    }
    return true;
  };
  const validateConfirmPin = () => {
    if (fieldsOfForm.confirmPin === '') {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Pins did not match. Try again' });
      return false;
    }
    if (fieldsOfForm.pin !== fieldsOfForm.confirmPin) {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Pins did not match. Try again' });
      return false;
    }
    return true;
  };

  const phoneKeypadHandler = (newValueOfField: string) => {
    if (newValueOfField.length <= 4) {
      setErrorsOfForm({ pin: '', confirmPin: '' });
      setFieldsOfForm({ ...fieldsOfForm, [activeField]: newValueOfField });
    }
  };
  const phoneKeypadHandler2 = (newValueOfField: string) => {
    if (newValueOfField.length <= 4) {
      setFieldsOfForm({ ...fieldsOfForm, ['confirmPin']: newValueOfField });
    }
  };
  const [loading, setLoading] = useState(false);

  const goToConfirmPin = async () => {
    if (!validatePin()) return;
    setActiveField('confirmPin');
    setFieldsOfForm({ ...fieldsOfForm, ['confirmationMode']: 'true' });
    return;
  };

  const addGuestToEvent = async () => {
    try {
      if (events.selectedEvent && customerStore?.custId) {
        dispatch(
          addGuestInEventAction({
            eventId: events.selectedEvent.eventID,
            custId: String(customerStore.custId),
          }),
        );
      }

      if (confirmationCodeReducer?.success) {
        dispatch(
          addGuestInEventAction({
            eventId: confirmationCodeReducer?.data?.eventId,
            custId: String(customerStore?.custId),
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updatePin = async () => {
    if (!validateConfirmPin()) {
      setActiveField('pin');
      fieldsOfForm.pin = '';
      fieldsOfForm.confirmPin = '';
      errorsOfForm.pin = 'Pins did not match. Please try again';
      setFieldsOfForm({ ...fieldsOfForm, ['confirmationMode']: 'false' });
      return;
    }
    const pinInHash = await sha256(fieldsOfForm.pin);
    dispatch(
      logInAntherWayUpdatePinAction({
        custId: customerStore.custId,
        fName: customerStore.fName,
        lName: customerStore.lName,
        birthDate: moment(customerStore.birthDate).format('MM-DD-YYYY'),
        cell: customerStore.cell,
        pin: pinInHash,
      }),
    );
    if (
      confirmationCodeReducer?.isEventList ||
      events.selectedEvent ||
      (confirmationCodeReducer?.success &&
        (terminalSettings?.checkInType?.event || terminalSettings?.checkInType.eventList))
    ) {
      addGuestToEvent();
    } else {
      history.replace(
        confirmationCodeReducer?.success && terminalSettings?.checkInType?.onlineBooking
          ? ROUTES.ASSIGN_ACTIVITIES
          : ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE,
      );
    }
  };

  const goBack = () => {
    if (location.state['prevRoute'] === 'LogInSecondStepScreen') {
      history.replace(ROUTES.LOG_IN_FIRST_STEP);
      return;
    }
    history.goBack();
  };

  return (
    <Fragment>
      <ContentContainer loading={loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div className={styles.rowMargin}>
              {fieldsOfForm.confirmationMode === 'false' && (
                <TextFieldWithLabel
                  type="password"
                  readOnly={!nativeKeyboardState}
                  value={fieldsOfForm.pin}
                  placeholder=""
                  required={true}
                  label="Create 4 Digit Pin"
                  invalid={!!errorsOfForm.pin}
                  errorMessage={errorsOfForm.pin}
                  onFocus={() => setActiveField('pin')}
                />
              )}

              {fieldsOfForm.confirmationMode === 'true' && (
                <TextFieldWithLabel
                  type="password"
                  readOnly={!nativeKeyboardState}
                  value={fieldsOfForm.confirmPin}
                  placeholder="Confirm 4 Digit Pin"
                  label="Confirm 4 Digit Pin"
                  invalid={!!errorsOfForm.confirmPin}
                  errorMessage={errorsOfForm.confirmPin}
                  onFocus={() => setActiveField('confirmPin')}
                />
              )}
            </div>
            <div className={styles.rowMargin}>
              {fieldsOfForm.confirmationMode === 'false' && (
                <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler} />
              )}
              {fieldsOfForm.confirmationMode === 'true' && (
                <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler2} />
              )}
            </div>

            <div className={styles.navigation}>
              <Button
                theme={'white'}
                handleClick={goBack}
                customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
              >
                Back
              </Button>
              <div className={styles.navigationFilter} />
              {fieldsOfForm.confirmationMode === 'false' && (
                <Button
                  theme="red"
                  handleClick={goToConfirmPin}
                  customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
                >
                  Continue
                </Button>
              )}
              {fieldsOfForm.confirmationMode === 'true' && (
                <Button
                  theme="red"
                  handleClick={updatePin}
                  customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
                >
                  Continue
                </Button>
              )}
            </div>
          </div>
        </div>
        <IdleMonitor />
      </ContentContainer>
      <WarningAlert
        isOpen={!events.eventExists && !!events.errorMessage}
        title=""
        description={events.errorMessage}
        handleClick={() => history.replace(ROUTES.ROOT)}
      />
    </Fragment>
  );
};

export default LogInAnotherWayEnterPinScreen;
