const CARD_MONTH = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

const CARD_YEAR = [
  { value: '21', label: '2021' },
  { value: '22', label: '2022' },
  { value: '23', label: '2023' },
  { value: '24', label: '2024' },
  { value: '25', label: '2025' },
  { value: '26', label: '2026' },
  { value: '27', label: '2027' },
  { value: '28', label: '2028' },
  { value: '29', label: '2029' },
  { value: '30', label: '2030' },
  { value: '31', label: '2031' },

];

export { CARD_MONTH, CARD_YEAR };
