import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import detectRequiredField from '../../utils/detectRequiredField';
import detectLabelField from '../../utils/detectLabelField';
import isAdult from '../../utils/isAdult';
import validator from '../../utils/validator';
import { GENDER, COUNTRIES, ROUTES } from '../../constants';
import IdleMonitor from '../../services/IdleMonitor';
import HeaderPanel from '../../components/HeaderPanel';
import WizardFormStep from '../../components/WizardFormStep';
import ContentContainer from '../../components/ContentContainer';
import { updateCommonDataOfNewCustomerAction } from '../../store/Customer/actions';
import { history } from '../../App';
import WarningAlert from '../../components/WarningAlert';
import { endCustomerSessionAction } from '../../store/General/actions';
import STATES from '../../constants/states';

type formType = {
  [key: string]: any;
};

const SignUpScreen = () => {
  const {
    source: sourceStore,
    registrationSettings: {
      adultRegistrationFields,
      newCustomerRegistrationSteps,
      allowDuplicateEmail,
      defaultCountry,
      adultAge,
    },
  } = useSelector(state => state);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeField, setActiveField] = useState(newCustomerRegistrationSteps[0]);
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({});
  const [errorsOfField, setErrorsOfField] = useState('');
  const [newCustomerIsMinor, setNewCustomerIsMinor] = useState(false);
  const [doNotMail, setDoNotMail] = useState(true);
  const [emailAddressIsUnavailable, setEmailAddressIsUnavailable] = useState(false);

  useEffect(() => {
    var birthDate = localStorage.getItem('login_another_way_birthdate')
      ? localStorage.getItem('login_another_way_birthdate')
      : '';
    var fName = localStorage.getItem('login_another_way_fName') ? localStorage.getItem('login_another_way_fName') : '';
    var lName = localStorage.getItem('login_another_way_lName') ? localStorage.getItem('login_another_way_lName') : '';
    if (defaultCountry) {
      setFieldsOfForm({
        ...fieldsOfForm,
        country: defaultCountry,
        fName: fName,
        lName: lName,
        birthDate: birthDate,
      });
    } else {
      setFieldsOfForm({ ...fieldsOfForm, fName: fName, lName: lName, birthDate: birthDate });
    }
  }, []);

  const onChangeField = async (fieldName: string, newFieldValue: any) => {
    try {
      setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
      setErrorsOfField('');
      if (fieldName === 'fName' || fieldName === 'lName') {
        await validator(
          activeField,
          newFieldValue,
          detectRequiredField(adultRegistrationFields, activeField),
          allowDuplicateEmail,
        );
      }
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const updateDoNotMail = () => {
    setDoNotMail(prevState => !prevState);
  };

  const goToNextStep = async () => {
    try {
      if (activeField === 'customerImage') {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 3000));
        setLoading(false);
      }

      await validator(
        activeField,
        fieldsOfForm[activeField],
        detectRequiredField(adultRegistrationFields, activeField),
        allowDuplicateEmail,
      );
      if (_.last(newCustomerRegistrationSteps) === activeField) {
        dispatch(updateCommonDataOfNewCustomerAction({ ...fieldsOfForm, doNotMail }));
        history.push(ROUTES.REVIEW_OF_NEW_CUSTOMERS);
        return;
      }

      if (activeField === 'birthDate') {
        if (!isAdult(fieldsOfForm.birthDate, adultAge)) {
          return setNewCustomerIsMinor(true);
        }
      }
      const indexOfCurrentField = newCustomerRegistrationSteps.indexOf(activeField);
      setActiveField(newCustomerRegistrationSteps[indexOfCurrentField + 1]);
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const goToPrevStep = () => {
    const indexOfCurrentField = newCustomerRegistrationSteps.indexOf(activeField);
    if (indexOfCurrentField <= 0) {
      dispatch(endCustomerSessionAction());
      return;
    }
    setActiveField(newCustomerRegistrationSteps[indexOfCurrentField - 1]);
    return setErrorsOfField('');
  };

  return (
    <Fragment>
      <ContentContainer loading={loading} bgColor="#FFFFFF">
        <HeaderPanel startOverButton shadow paginationHistory="Customer" paginationStep={''} />
        <WizardFormStep
          type="numeric"
          fieldName="birthDate"
          value={fieldsOfForm['birthDate'] || ''}
          label={detectLabelField(adultRegistrationFields, 'birthDate') + ' MM/DD/YYYY'}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'birthDate' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'birthDate')}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
        />
        <WizardFormStep
          type="text"
          fieldName="fName"
          value={fieldsOfForm['fName']}
          label={detectLabelField(adultRegistrationFields, 'fName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'fName' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'fName')}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
        />
        <WizardFormStep
          type="text"
          fieldName="lName"
          value={fieldsOfForm['lName']}
          label={detectLabelField(adultRegistrationFields, 'lName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'lName' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'lName')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="cell"
          value={fieldsOfForm['cell']}
          label={detectLabelField(adultRegistrationFields, 'cell')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'cell' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'cell')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="email"
          fieldName="emailAddress"
          value={fieldsOfForm['emailAddress']}
          label={detectLabelField(adultRegistrationFields, 'emailAddress')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'emailAddress' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'emailAddress')}
          doNotMail={!doNotMail}
          setDoNotMail={updateDoNotMail}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="radio"
          fieldName="gender"
          value={fieldsOfForm['gender']}
          label={detectLabelField(adultRegistrationFields, 'gender')}
          options={GENDER}
          valueField="value"
          displayField="title"
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'gender' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'gender')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="racerName"
          value={fieldsOfForm['racerName']}
          label={detectLabelField(adultRegistrationFields, 'racerName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'racerName' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'racerName')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="address"
          value={fieldsOfForm['address']}
          label={detectLabelField(adultRegistrationFields, 'address')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'address' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'address')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="address2"
          value={fieldsOfForm['address2']}
          label={detectLabelField(adultRegistrationFields, 'address2')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'address2' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'address2')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="city"
          value={fieldsOfForm['city']}
          label={detectLabelField(adultRegistrationFields, 'city')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'city' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'city')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type={fieldsOfForm['country'] === 'United States' ? 'typeSelector' : 'text'}
          fieldName="state"
          value={fieldsOfForm['state']}
          options={STATES}
          displayField={detectLabelField(adultRegistrationFields, 'state')}
          label={detectLabelField(adultRegistrationFields, 'state')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'state' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'state')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="typeSelector"
          fieldName="country"
          value={fieldsOfForm['country']}
          options={COUNTRIES}
          displayField={detectLabelField(adultRegistrationFields, 'country')}
          label={detectLabelField(adultRegistrationFields, 'country')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'country' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'country')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="zip"
          value={fieldsOfForm['zip']}
          label={detectLabelField(adultRegistrationFields, 'zip')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'zip' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'zip')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="itemSelect"
          fieldName="sourceId"
          value={fieldsOfForm['sourceId']}
          label={detectLabelField(adultRegistrationFields, 'sourceId')}
          options={sourceStore.data}
          valueField="sourceId"
          displayField="sourceName"
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'sourceId' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'sourceId')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="picture"
          fieldName="customerImage"
          value={fieldsOfForm['customerImage']}
          label="Picture"
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'customerImage' === activeField}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="licenseNumber"
          value={fieldsOfForm['licenseNumber']}
          label={detectLabelField(adultRegistrationFields, 'licenseNumber')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'licenseNumber' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'licenseNumber')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom1"
          value={fieldsOfForm['custom1']}
          label={detectLabelField(adultRegistrationFields, 'custom1')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom1' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'custom1')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom2"
          value={fieldsOfForm['custom2']}
          label={detectLabelField(adultRegistrationFields, 'custom2')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom2' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'custom2')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom3"
          value={fieldsOfForm['custom3']}
          label={detectLabelField(adultRegistrationFields, 'custom3')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom3' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'custom3')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom4"
          value={fieldsOfForm['custom4']}
          label={detectLabelField(adultRegistrationFields, 'custom4')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom4' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'custom4')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />

        <WarningAlert
          isOpen={newCustomerIsMinor}
          title="Whoops!"
          description={`The first person to register must be an adult
           If you're here without an adult, please see a cashier for help.`}
          handleClick={() => setNewCustomerIsMinor(false)}
        />

        <WarningAlert
          isOpen={emailAddressIsUnavailable}
          title="Whoops!"
          description={`This email address is taken.
          Please use another email address,
          or if you believe you’ve reached this message by mistake,
          log out and see a cashier for help.`}
          handleClick={() => setEmailAddressIsUnavailable(false)}
        />
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default SignUpScreen;
