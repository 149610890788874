import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import addNewButton from '../../assets/icons/add-new-button.svg';

interface CircleButtonProps {
  //   children: React.ReactNode;
  onClick?: () => void;
  text: string;
  icon: string;
  color: string;
  buttonWrapperClass?: string;
  buttonIconClass?: string;
  buttontextClass?: string;
}
const IconWithText: React.FC<CircleButtonProps> = ({
  onClick,
  text,
  icon,
  color,
  buttonWrapperClass,
  buttonIconClass,
  buttontextClass,
}: CircleButtonProps) => {
  return (
    <div className={cn(styles.circleButtonInner, buttonWrapperClass)} onClick={onClick}>
      <div>
        <button className={cn(styles.addGuestButton, buttonIconClass)}>
          <img src={icon} alt="" />
        </button>
        <h1 className={cn(buttontextClass)}>{text}</h1>
      </div>
    </div>
  );
};

export default IconWithText;
