import React from 'react';
import MultiLineTextFieldWithLabel from '../MultiLineTextFieldWithLabel';
import Keyboard from '../Keyboard';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

interface Props {
  value: string | number;
  fieldName: string;
  label?: any;
  placeholder?: string;
  inputType?: string;
  invalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  keyboardLayout?: string;
  onChange: (fieldName: string, newValue: string) => void;
  enterClick?: () => void;
  children?: React.ReactNode;
}

const WizardFormMultiLineField = ({
  value,
  fieldName,
  label,
  placeholder,
  inputType,
  invalid,
  errorMessage,
  required,
  keyboardLayout,
  onChange,
  children,
  enterClick,
}: Props) => {
  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const onKeyboardPress = (newFieldValue: string) => {
    onChange(fieldName, newFieldValue);
  };

  const onChangeValue = (e: any) => {
    onChange(fieldName, e.target.value);
  };

  return (
    <div className={styles.wizardFormField}>
      <div className={styles.wizardFormFieldInner}>
        <MultiLineTextFieldWithLabel
          type={inputType}
          value={value}
          readOnly={!nativeKeyboardState}
          placeholder={placeholder}
          onChange={onChangeValue}
          onFocus={() => {}}
          label={label}
          invalid={invalid}
          errorMessage={errorMessage}
          required={required}
        />
        {children}
      </div>
      {!nativeKeyboardState && (
        <div className={styles.keyboard}>
          <Keyboard
            sentenceMode={true}
            valueOfField={value}
            onChange={onKeyboardPress}
            hideKeyboard={() => {}}
            handleEnter={enterClick}
            layout={keyboardLayout}
          />
        </div>
      )}
    </div>
  );
};

WizardFormMultiLineField.defaultProps = {
  label: '',
  placeholder: '',
  inputType: 'text',
  invalid: false,
  errorMessage: '',
  required: false,
  keyboardLayout: 'default',
  enterClick: () => {},
};

export default WizardFormMultiLineField;
