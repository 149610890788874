import {
  CREATE_PAY_BY_LINK_TYPES,
  UPDATE_KDS_DATA_TYPES,
  CANCEL_UPDATE_KDS_DATA,
  UPDATE_KDS_DATA_START,
} from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';

export const initialState = {
  inProcess: false,
  kdsEnabled: false,
  paymentLinkId: null,
  checkId: null,
  amount: null,
  expirationDate: null,
  linkSourceId: null,
  isPaid: false,
  createdDate: null,
  kdsRequestItems: null,
  loading: false,
  phoneNumber: null,
};

export type InitialStateType = typeof initialState;

export default function payByLinkReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case UPDATE_KDS_DATA_START:
      return {
        ...state,
        kdsEnabled: payload.kdsEnabled,
        inProcess: true,
      };
    case UPDATE_KDS_DATA_TYPES.REQUEST:
      return {
        ...state,
        inProcess: true,
      };
    case UPDATE_KDS_DATA_TYPES.SUCCESS:
      return {
        ...state,
        kdsRequestItems: payload,
        inProcess: false,
      };
    case UPDATE_KDS_DATA_TYPES.FAILURE:
      return {
        ...state,
        inProcess: false,
      };
    case CANCEL_UPDATE_KDS_DATA:
      return {
        ...initialState,
      };
    case CREATE_PAY_BY_LINK_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PAY_BY_LINK_TYPES.SUCCESS:
      return {
        ...state,
        kdsRequestItems: payload.kdsRequestItems,
        kdsEnabled: payload.kdsEnabled,
        paymentLinkId: payload.paymentLinkId,
        checkId: payload.checkId,
        amount: payload.amount,
        expirationDate: payload.expirationDate,
        linkSourceId: payload.linkSourceId,
        isPaid: payload.isPaid,
        createdDate: payload.createdDate,
        phoneNumber: payload.phoneNumber,
        loading: false,
      };
    case CREATE_PAY_BY_LINK_TYPES.FAILURE:
      return {
        ...state,
        paymentLinkId: null,
        checkId: null,
        amount: null,
        expirationDate: null,
        linkSourceId: null,
        isPaid: false,
        createdDate: null,
        loading: false,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
