import React from 'react';
import ReactMobileDatepicker from 'react-mobile-datepicker';
import styles from './styles.module.scss';
import './datepickerStyles.css';

interface DatePickerHOCProps {
  value: Date;
  max?: Date;
  onSelect: (value: string) => void;
}

const DatePickerHOC: React.FC<DatePickerHOCProps> = ({ value, max, onSelect }: DatePickerHOCProps) => {
  const monthMap = {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };

  const dateConfig = {
    month: {
      format: value => monthMap[value.getMonth() + 1],
      caption: 'Mon',
      step: 1,
    },
    date: {
      format: 'DD',
      caption: 'Day',
      step: 1,
    },
    year: {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
    },
  };

  return (
    <div className={styles.dp}>
      <ReactMobileDatepicker
        isOpen={true}
        isPopup={false}
        value={value}
        min={new Date(1900, 0, 1)}
        max={new Date()}
        onChange={onSelect}
        dateConfig={dateConfig}
        showCaption={false}
        showHeader={false}
        showFooter={false}
      />
    </div>
  );
};

DatePickerHOC.defaultProps = {
  max: new Date(2050, 0, 1),
};

export default DatePickerHOC;
