export const REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES = {
  REQUEST: 'customerGroup/REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES_REQUEST',
  SUCCESS: 'customerGroup/REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES_SUCCESS',
  FAILURE: 'customerGroup/REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES_FAILURE',
};

export const removeMemberFromCustomerRelationshipsAction = (payload: any) => ({
  type: REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES.REQUEST,
  payload,
});

export const removeMemberFromCustomerRelationshipsActionSuccess = (payload: any) => ({
  type: REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES.SUCCESS,
  payload,
});

export const removeMemberFromCustomerRelationshipsActionFailure = () => ({
  type: REMOVE_MEMBER_FROM_CUSTOMER_RELATIONSHIPS_TYPES.FAILURE,
});
