export const CONVENIENCE_FEES = {
  REQUEST: 'convenienceFees/GET_REQUEST',
  SUCCESS: 'convenienceFees/GET_SUCCESS',
  FAILURE: 'convenienceFees/GET_FAILURE',
};

export const getConvenienceFees = () => ({
  type: CONVENIENCE_FEES.REQUEST,
});

export const getConvenienceFeesSuccess = data => ({
  type: CONVENIENCE_FEES.SUCCESS,
  payload: data,
});

export const getConvenienceFeesFailure = () => ({
  type: CONVENIENCE_FEES.FAILURE,
});
