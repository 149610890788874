import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props {
  text: string;
  onClick: () => void;
  selected: boolean;
}

const EventListItem = ({ text, onClick, selected }: Props) => {
  return (
    <div className={cn(styles.eventButtonStyle, selected && styles.selectedEvent)} onClick={onClick}>
      <h2>{text}</h2>
    </div>
  );
};

export default EventListItem;
