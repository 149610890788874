import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startPaymentAction } from '../../store/Payment/actions';
import CartItemsList from './CartItemsList';
import CartItem from './CartItem';
import CartSummary from './CartSummary';
import Button from '../Button';
import KDSform from '../KDSform';
import styles from './styles.module.scss';
import useSticky from '../../hooks/useSticky';
import cn from 'classnames';
import { useCartItemsListHeight } from '../../hooks';

const Cart = () => {
  const cartRef = React.useRef(null);

  const { isSticky } = useSticky(cartRef, 60);
  const cartItemsListHeight = useCartItemsListHeight({ isSticky });

  const dispatch = useDispatch();
  const {
    cartStore: { checkDetails, checkSubtotal, checkTax, checkTotal, loading },
    customerStore,
  } = useSelector(state => state);

  const [formIsOpen, setFormIsOpen] = React.useState(false);

  const startCheckout = () => {
    dispatch(startPaymentAction({}));

    if (!customerStore?.custId) {
      setFormIsOpen(true);
    }
  };

  return (
    <>
      <div className={styles.cart__wrapper}>
        <div className={cn(styles.cart, { [styles.cart__isSticky]: isSticky })} ref={cartRef}>
          <div id="cartTitle" className={styles.cartTitle}>
            <span>Cart</span>
          </div>
          <div
            className={styles.cartItemsInner}
            style={{ height: cartItemsListHeight > 0 ? `${cartItemsListHeight}px` : '100%' }}
          >
            <CartItemsList>
              {checkDetails.map((item: any) => (
                <CartItem
                  key={item.checkDetailId}
                  currentCartItem={item}
                  checkDetailId={item.checkDetailId}
                  productId={item.productId}
                  productType={item.productType}
                />
              ))}
            </CartItemsList>
          </div>
          <div id="cartTotalAmount">
            <div className={styles.cartLine} />
            <CartSummary subtotal={checkSubtotal} tax={checkTax} total={checkTotal} />
            <div className={styles.cartCheckout}>
              <Button
                handleClick={startCheckout}
                disabled={!checkDetails.length}
                customClass={styles.buttonCheckout}
                theme="red"
              >
                Checkout
              </Button>
            </div>
          </div>
          {loading && (
            <div className={styles.loaderWrapper}>
              <div className={styles.loader} />
            </div>
          )}
        </div>
      </div>
      <KDSform isOpen={formIsOpen} setIsOpen={setFormIsOpen} />
    </>
  );
};

export default Cart;
