import React from 'react';
import moment from 'moment';
import TextFieldWithLabel from '../TextFieldWithLabel';
import DatePicker from '../DatePicker';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

interface Props {
  value: string | number;
  fieldName: string;
  label?: string;
  placeholder?: string;
  inputType?: string;
  required?: boolean;
  onChange: (newValue: string) => void;
}

const WizardFormDatePicker = ({ value, fieldName, label, placeholder, required, onChange }: Props) => {
  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  return (
    <div className={styles.wizardFormField}>
      <div className={styles.mainContent}>
        <TextFieldWithLabel
          type="text"
          readOnly={!nativeKeyboardState}
          value={moment(value).format('MMMM DD YYYY')}
          placeholder={placeholder}
          onFocus={() => {}}
          label={label}
          required={required}
        />
      </div>
      <div className={styles.select}>
        <DatePicker value={moment(value).toDate()} onSelect={onChange} />
      </div>
    </div>
  );
};

var thisYear = new Date();
thisYear.setFullYear(thisYear.getFullYear() - 25);

WizardFormDatePicker.defaultProps = {
  label: '',
  placeholder: '',
  inputType: 'text',
  required: false,
  value: thisYear.toString(),
};

export default WizardFormDatePicker;
