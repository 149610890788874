import React from 'react';
import IdleMonitor from '../../../services/IdleMonitor';
import ContentContainer from '../../ContentContainer';
import styles from './styles.module.scss';
import FireWorkIcon from '../../../assets/icons/fireworks.svg';
import { history } from '../../../App';

const CheckInComplete = () => {
  return (
    <ContentContainer theme="red" loading={false}>
      <div className={styles.root} onClick={() => history.push('/')}>
        <h1>Check-In Complete</h1>
        <img src={FireWorkIcon} alt="" />
        <h1>Thank you!</h1>
      </div>
      <IdleMonitor />
    </ContentContainer>
  );
};

export default CheckInComplete;
