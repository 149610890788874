import React from 'react';
import fireworks from '../../assets/icons/fireworks.svg';
import { history } from '../../App';

import styles from './styles.module.scss';
import { ROUTES } from '../../constants';

const CongratulationsScreen = () => {
  const onClickHandler = () => {
    history.replace(ROUTES.ROOT);
  };

  return (
    <div className={styles.screenWrapper} onClick={() => onClickHandler()}>
      <div className={styles.contentWrapper}>
        <div>
          <h1>Check-In Complete</h1>
        </div>
        <div className={styles.imageWrapper}>
          <img src={fireworks} alt="firework" className={styles.image} />
        </div>
        <div>
          <h1>Thank you!</h1>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsScreen;
