import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../Modal';
import MemberCard from '../MemberCard';
import Button from '../Button';
import Title from '../Title';
import styles from './styles.module.scss';
import IconWithText from '../IconWithText';
import addNewButton from '../../assets/icons/add-new-button.svg';

interface ChooseParticipantProps {
  isOpen: boolean;
  members: any[];
  cancelClickHandler: Function;
  doneClickHandler: Function;
  addGuestHandler: () => void;
  loading?: boolean;
}

const ChooseParticipant = ({
  isOpen,
  members,
  cancelClickHandler,
  addGuestHandler,
  doneClickHandler,
  loading = false,
}: ChooseParticipantProps) => {
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const {
    purchaseFlowStore: { unavailableCustomers },
  } = useSelector(state => state);

  useEffect(() => {
    setSelectedParticipants([...selectedParticipants]);
    handleResizeDetailsInner();
    window.addEventListener('resize', handleResizeDetailsInner);
    return () => {
      window.removeEventListener('resize', handleResizeDetailsInner);
    };
  }, []);

  const handleResizeDetailsInner = () => {
    // const heightChooseMembers = document.getElementById('chooseMembers').offsetHeight;
    // const heightChooseMembersHeader = document.getElementById('chooseMembersHeader').offsetHeight;
    // const heightChooseMembersFooter = document.getElementById('chooseMembersFooter').offsetHeight;
    // setHeightContent(heightChooseMembers - (heightChooseMembersHeader + heightChooseMembersFooter));
  };

  const chooseParticipant = item => {
    if (!unavailableCustomers || unavailableCustomers.indexOf(item.custId) === -1) {
      if (selectedParticipants.includes(item.custId)) {
        const selectedParticipantsCopy = selectedParticipants;
        const index = selectedParticipantsCopy.indexOf(item.custId);
        selectedParticipantsCopy.splice(index, 1);
        return setSelectedParticipants([...selectedParticipantsCopy]);
      }
      setSelectedParticipants([...selectedParticipants, item.custId]);
    }
  };

  const doneClickHandlerWrap = () => {
    const selectedMembers = [];
    members.map(item => {
      if (selectedParticipants.includes(item.custId)) {
        selectedMembers.push(item);
      }
    });
    return doneClickHandler(selectedMembers);
  };

  return (
    <Modal isOpen={isOpen}>
      <div id="chooseMembers" className={styles.chooseParticipant}>
        <Title message="Who are you purchasing for?" />
        <div className={styles.buttonWrapper}>
          <IconWithText icon={addNewButton} text="ADD MORE GUESTS" color="red" onClick={addGuestHandler} />
        </div>
        <div className={styles.chooseParticipantContent}>
          <div className={styles.membersListContainer}>
            {/*<div className={styles.membersListContainerInner}>*/}
            {members.map((item, index) => (
              <MemberCard
                key={index}
                handleClick={() => chooseParticipant(item)}
                isActive={selectedParticipants.includes(item.custId)}
                custId={item.custId}
                firstName={item.fName}
                lastName={item.lName}
                birthday={item.birthDate}
                gender={item.gender}
                error={
                  unavailableCustomers &&
                  unavailableCustomers?.indexOf(item.custId) !== -1 &&
                  `${item.fName} is participating in another activity at this time`
                }
              />
            ))}
            {/*</div>*/}
          </div>
        </div>
        <div id="chooseMembersFooter" className={styles.navigation}>
          <div className={styles.columnLeft}>
            <Button handleClick={() => cancelClickHandler()} customClass={styles.whiteButton} theme="white">
              Cancel
            </Button>
          </div>
          <div className={styles.columnCenter}></div>
          <div className={styles.columnRight}>
            <Button
              handleClick={doneClickHandlerWrap}
              disabled={loading || !selectedParticipants.length}
              customClass={styles.redButton}
              theme="red"
            >
              Continue
            </Button>
          </div>
          <div className={styles.navigationFilter} />
        </div>
      </div>
    </Modal>
  );
};

ChooseParticipant.defaultProps = {
  isOpen: false,
  members: [],
};

export default ChooseParticipant;
