import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IdleMonitor from '../../services/IdleMonitor';
import { verifyResetCodeAction, startResetPinAction } from '../../store/Customer/actions';
import ContentContainer from '../../components/ContentContainer';
import PhoneKeypad from '../../components/PhoneKeypad';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import cn from 'classnames';
import HeaderPanel from '../../components/HeaderPanel';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

const ResetPinScreen = () => {
  const dispatch = useDispatch();
  const { customerStore, resetPinStore } = useSelector(state => state);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [resetCode, setResetCode] = useState('');

  const phoneKeypadHandler = (newValueOfField: string) => {
    setResetCode(newValueOfField);
  };

  const verifyResetCode = () => {
    dispatch(verifyResetCodeAction({ custId: customerStore.custId, resetCode }));
  };

  const resendResetCode = () => {
    dispatch(startResetPinAction({ customerData: customerStore }));
  };

  return (
    <Fragment>
      <ContentContainer loading={resetPinStore.loading || customerStore.loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div>
              <h1 className={styles.mainMessage}>A reset code has been sent to your phone</h1>
            </div>

            <div className={styles.rowMargin}>
              <TextFieldWithLabel
                readOnly={!nativeKeyboardState}
                type="text"
                value={resetCode}
                placeholder=""
                label="Reset Code"
                onFocus={() => {}}
              />
            </div>
            <div className={styles.rowMargin}>
              <PhoneKeypad valueOfField={resetCode} onChange={phoneKeypadHandler} />
            </div>

            <div className={styles.navigation}>
              <Button
                theme={'white'}
                handleClick={resendResetCode}
                customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
              >
                Resend Code
              </Button>
              <div className={styles.navigationFilter} />
              <Button
                theme="red"
                handleClick={verifyResetCode}
                customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default ResetPinScreen;
