import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { findIndex } from 'lodash';
import * as Api from '../../api';
import { history } from '../../App';

import { addItemToCartAction } from '../../store/Cart/actions';
import update from 'immutability-helper';
import Prompts from '../../components/Prompts';

import {
  hideSelectionOfParticipant,
  showSelectionOfParticipant,
  updateStatusNotEnoughTimeSlots,
} from '../../store/PurchaseFlow/actions';

import ContentContainer from '../../components/ContentContainer';
import IdleMonitor from '../../services/IdleMonitor';
import HeaderPanel from '../../components/HeaderPanel';
import Cart from '../../components/Cart';
import ChooseParticipant from '../../components/ChooseParticipant';
import AddGuestForm from '../../components/AddGuestForm';
import WarningAlert from '../../components/WarningAlert';
import Button from '../../components/Button';
import EnforceWaiver from '../../components/EnforceWaiver';
import Confirm from '../../components/Prompt';
import styles from './styles.module.scss';
import ProductMembershipRequired from '../../components/ProductMembershipRequired';
import { getPrice } from '../../utils/core';
import ProductSchedule from '../../components/ProductSchedule';

const ProductScreen = () => {
  // @ts-ignore
  const { categoryId, productId } = useParams();
  const dispatch = useDispatch();
  const {
    terminalSettings: { checkInType },
    customerStore,
    customerGroup: customerGroupState,
    cartStore: { loading: cartLoading, checkId },
    purchaseFlowStore: { selectionOfParticipant, notEnoughTimeSlots, loading: purchaseFlowLoading },
    membershipStore,
  } = useSelector(state => state);

  const [loading, setLoading] = React.useState(false);

  const [productDetail, setProductDetail] = React.useState({
    productId: null,
    title: '',
    description: '',
    largeIcon: '',
    highResImagePath: '',
    price1: 0,
    productType: 0,
    isRequiredMembership: false,
    isSchedulable: false,
    explanation: '',
  });

  const [productPack, setProductPack] = React.useState(0);
  const [timeSlots, setTimeSlots] = React.useState([]);
  const [showListOfGuests, setShowListOfGuests] = React.useState(selectionOfParticipant);

  const scheduledDate = moment().format('YYYY-MM-DD');
  const [currentTimeSlot, setCurrentTimeSlot] = React.useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = React.useState([]);

  const [showAddGuest, setShowAddGuest] = React.useState(false);
  const [membersForCart, setMembersForCart] = React.useState([]);
  const [membersWithoutWaiver, setMembersWithoutWaiver] = React.useState([]);
  const [incompleteWaiver, setIncompleteWaiver] = React.useState(false);
  const [enforceWaiverInProcess, setEnforceWaiverInProcess] = React.useState(false);
  const [prompts, setPrompts] = React.useState(null);
  const [qty, setQty] = React.useState(0);
  const [promptTempMembers, setPromptTempMembers] = React.useState(null);

  const fetchProduct = async () => {
    try {
      const { data: productsOfCategory } = await Api.fetchProductsByCategory(categoryId);
      return productsOfCategory.find(x => `${x.productId}` === productId);
    } catch (error) {}
  };

  const fetchTimeSlots = async (selectedScheduledDates = null) => {
    try {
      setLoading(true);
      const { data: dataTimeSlots } = await Api.fetchTimeSlots(
        scheduledDate,
        productId,
        selectedScheduledDates,
        checkId,
      );
      setTimeSlots(dataTimeSlots);
      setLoading(false);
    } catch (error) {}
  };

  React.useEffect(() => {
    const initProductData = async () => {
      setLoading(true);
      const productsData = await fetchProduct();
      setProductDetail({ ...productsData });
      if (productsData.pack && productsData.pack > 1) {
        setProductPack(productsData.pack);
      }
      await fetchTimeSlots();
      setLoading(false);
    };
    initProductData();
  }, []);

  React.useEffect(() => {
    if (!!notEnoughTimeSlots) {
      setLoading(false);
    }
  }, [notEnoughTimeSlots]);

  // Checking timeslots
  const timeSlotHandler = async ({ item, trackNo }) => {
    if (productPack) {
      setLoading(true);
      const index = findIndex(selectedTimeSlots, (i: any) => {
        return i.scheduledTime === item.scheduledTime && i.trackNo === trackNo;
      });

      // Remove/Clear selected timeslot
      if (index >= 0) {
        const a = selectedTimeSlots.filter((i: any) => {
          return i.scheduledTime !== item.scheduledTime;
        });
        await fetchTimeSlots(
          a.map(time => {
            return {
              selectedScheduledDate: time.scheduledTime,
              ...(time.trackNo && { trackNo: time.trackNo }),
            };
          }),
        );
        return setSelectedTimeSlots([...a]);
      }
      if (item.timeSlotStatus === 0) {
        // Do not select more timeslots than user has
        if (selectedTimeSlots.length === productPack) {
          setLoading(false);
          return;
        }

        const selected = [...selectedTimeSlots, { ...item, ...(trackNo && { trackNo }) }];
        await fetchTimeSlots(
          selected.map(time => {
            return {
              selectedScheduledDate: time.scheduledTime,
              ...(time.trackNo && { trackNo: time.trackNo }),
            };
          }),
        );
        setSelectedTimeSlots(selected);
      }

      setLoading(false);
    } else {
      if (item.timeSlotStatus === 0) {
        setCurrentTimeSlot({ ...item, ...(trackNo && { trackNo }) });
        setShowListOfGuests(true);
        dispatch(
          showSelectionOfParticipant({
            checkId,
            productId: productDetail.productId,
            scheduledTime: [item.scheduledTime],
          }),
        );
      }
    }
  };

  const clearSelectedTimeSlots = async () => {
    await fetchTimeSlots();
    setSelectedTimeSlots([]);
  };

  const nextClickHandler = () => {
    if (selectedTimeSlots.length > 0) {
      setShowListOfGuests(true);
      dispatch(
        showSelectionOfParticipant({
          checkId,
          productId: productDetail.productId,
          scheduledTime: selectedTimeSlots.map(time => time.scheduledTime),
        }),
      );
    }
  };

  const cancelClickHandler = () => {
    setShowListOfGuests(false);
    dispatch(hideSelectionOfParticipant());
  };

  const checkWaivers = async members => {
    try {
      setMembersForCart([...members]);
      setMembersWithoutWaiver([]);
      const arr = [];
      for (const member of members) {
        const findMember = members.find(customer => customer.custId === member.custId);
        if (findMember.status1 !== 2) {
          arr.push(findMember);
        }
      }
      if (arr.length) {
        setMembersWithoutWaiver(arr);
        setIncompleteWaiver(true);
        throw 'Error';
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const finishEnforceWaiver = async membersEnforce => {
    try {
      setEnforceWaiverInProcess(false);
      await doneClickHandler(membersEnforce);
    } catch (e) {
      console.log({ e });
    }
  };

  const closeEnforceWaiver = () => {
    setEnforceWaiverInProcess(false);
  };

  const addToCart = members => {
    const arr = [];
    if (productPack) {
      members.map(item => {
        const itemData = {
          productId: productDetail.productId,
          activitiesToSchedule: selectedTimeSlots.map((i: any) => {
            return {
              ...(i.trackNo && { trackNo: i.trackNo }),
              activityId: i.activityId,
              scheduledTime: i.scheduledTime,
            };
          }),
          custId: item.custId,
          quantity: 1,
        };
        arr.push(itemData);
      });
      setShowListOfGuests(false);
      dispatch(hideSelectionOfParticipant());

      dispatch(
        addItemToCartAction({
          productType: productDetail.productType,
          checkDetails: [...arr],
          isRequiredMembership: productDetail.isRequiredMembership,
          isSchedulable: productDetail.isSchedulable,
        }),
      );
    } else {
      members.map((item: any) => {
        const itemData = {
          productId: productDetail.productId,
          activityId: currentTimeSlot.activityId,
          activitiesToSchedule: [
            {
              ...(currentTimeSlot.trackNo && { trackNo: currentTimeSlot.trackNo }),
              activityId: currentTimeSlot.activityId,
              scheduledTime: currentTimeSlot.scheduledTime,
            },
          ],
          custId: item.custId,
          quantity: 1,
        };
        arr.push(itemData);
      });

      dispatch(
        addItemToCartAction({
          productType: productDetail.productType,
          checkDetails: [...arr],
          isRequiredMembership: productDetail.isRequiredMembership,
          isSchedulable: productDetail.isSchedulable,
        }),
      );
    }
  };

  const setPromptsData = ({ quantity, promptData, members = null }) => {
    setQty(quantity);
    if (members) {
      setPromptTempMembers(members);
    }
    const prompts = promptData.map((prompt, idx): any => {
      const labels = prompt.promptLabels.map((label): any => {
        return {
          ...label,
          checked: false,
        };
      });
      return {
        ...prompt,
        isOpen: idx === 0,
        promptLabels: labels,
        disabledContinue: labels && labels.length,
      };
    });
    setPrompts(prompts);
  };

  const doneClickHandler = async members => {
    try {
      setLoading(true);
      await fetchTimeSlots(
        selectedTimeSlots.map(time => {
          return {
            selectedScheduledDate: time.scheduledTime,
            ...(time.trackNo && { trackNo: time.trackNo }),
          };
        }),
      );

      if (productPack) {
        let minSlotsRemaining = Number.MAX_SAFE_INTEGER;

        timeSlots.forEach((slots: any) => {
          const trackNo = slots.trackNo;

          slots.timeslots.forEach((slot: any) => {
            const selectedScheduleTime = selectedTimeSlots.find(
              selectedSlot => selectedSlot.scheduledTime === slot.scheduledTime,
            );

            if (
              selectedScheduleTime &&
              selectedScheduleTime?.trackNo === trackNo &&
              slot.slotsRemaining < minSlotsRemaining
            ) {
              minSlotsRemaining = slot.slotsRemaining;
            }
          });
        });

        if (minSlotsRemaining < selectedTimeSlots.length) {
          dispatch(updateStatusNotEnoughTimeSlots({ notEnoughTimeSlots: true }));
          setLoading(false);
          return;
        }
      } else {
        let availableSlots;
        if (timeSlots.length > 1) {
          const slots = timeSlots.find(x => x.trackNo === currentTimeSlot.trackNo);
          availableSlots = slots.timeslots.find(x => x.scheduledTime === currentTimeSlot.scheduledTime).slotsRemaining;
        } else {
          availableSlots = timeSlots[0].timeslots.find(x => x.scheduledTime === currentTimeSlot.scheduledTime)
            .slotsRemaining;
        }

        if (availableSlots < members.length) {
          dispatch(updateStatusNotEnoughTimeSlots({ notEnoughTimeSlots: true }));
          setLoading(false);
          return;
        }
      }
      await checkWaivers(members);
      const {
        data: { prompts },
      } = await Api.fetchProductPrompts(productDetail.productId);
      if (!prompts.length) {
        addToCart(members);
      } else {
        setPromptsData({ members, quantity: 1, promptData: prompts });
        setLoading(false);
      }
    } catch (e) {
      console.log({ e });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (membershipStore.customers) {
      setLoading(false);
    }
  }, [membershipStore.customers]);

  const showNextButton = () => {
    if (!selectedTimeSlots.length) {
      return false;
    }

    if (productPack !== selectedTimeSlots.length && checkInType.maximumSelection) {
      return false;
    }

    return true;
  };

  return (
    <>
      <ContentContainer loading={loading || cartLoading || purchaseFlowLoading || membershipStore.isLoading}>
        <HeaderPanel backButton handleBackClick={() => history.goBack()} logOutButton border />

        <div className={styles.container}>
          <div className={styles.content}>
            <div id="productTitle" className={styles.panel}>
              <h2 className={styles.title}>{productDetail.description}</h2>
              <div className={styles.price}>
                <div className={styles.priceInner}>${getPrice(productDetail, customerStore.priceLevel)}</div>
              </div>
            </div>
            <div id="productDetail" className={styles.productInformation}>
              <div className={styles.productCover}>
                <img
                  src={
                    productDetail.highResImagePath
                      ? productDetail.highResImagePath
                      : `data:image/jpeg;base64,${productDetail.largeIcon}`
                  }
                  alt=""
                />
              </div>
              <div className={styles.productDescription}>
                <div>{productDetail.explanation}</div>
              </div>
              {!!productPack && (
                <div className={styles.packSlotsCounter}>
                  {!selectedTimeSlots.length &&
                    (checkInType.maximumSelection ? (
                      <span>Choose {productPack} Time Slots</span>
                    ) : (
                      <span>Choose up to {productPack} Time Slots</span>
                    ))}
                  {!!selectedTimeSlots.length && (
                    <span>{productPack - selectedTimeSlots.length} Time Slots Remaining</span>
                  )}
                </div>
              )}
            </div>
            <ProductSchedule
              selectedTimeSlots={selectedTimeSlots}
              timeSlots={timeSlots}
              timeSlotHandler={timeSlotHandler}
              resourceGroup={timeSlots.length > 1}
            />
            <div id="footerPanel" className={styles.footerPanel}>
              {!!selectedTimeSlots.length && (
                <Button customClass={styles.cancelButton} handleClick={clearSelectedTimeSlots} theme="white">
                  Cancel
                </Button>
              )}
              {showNextButton() && (
                <Button customClass={styles.nextButton} handleClick={nextClickHandler} theme="red">
                  Next
                </Button>
              )}
            </div>
          </div>
          <Cart />
          {showAddGuest && (
            <AddGuestForm
              endAddingGuestProcessHandler={() => {
                setShowAddGuest(false);
                setShowListOfGuests(true);
              }}
            />
          )}
        </div>
      </ContentContainer>

      {showListOfGuests && (
        <ChooseParticipant
          isOpen={selectionOfParticipant}
          members={[customerStore, ...customerGroupState.members]}
          cancelClickHandler={cancelClickHandler}
          addGuestHandler={() => {
            setShowListOfGuests(false);
            setShowAddGuest(true);
          }}
          doneClickHandler={doneClickHandler}
        />
      )}

      {enforceWaiverInProcess && (
        <EnforceWaiver
          membersWithoutWaiver={membersWithoutWaiver}
          members={membersForCart}
          finishEnforceWaiver={finishEnforceWaiver}
          closeEnforceWaiver={closeEnforceWaiver}
        />
      )}

      <Confirm
        isOpen={incompleteWaiver}
        title="Incomplete Waiver"
        titleButtonCancel="Return"
        titleButtonOk="Continue"
        handleReturnClick={() => setIncompleteWaiver(false)}
        handleContinueClick={() => {
          setIncompleteWaiver(false);
          setEnforceWaiverInProcess(true);
        }}
      >
        <div className={styles.incompleteWaiver}>
          <p>The following guests do not have the required waiver:</p>
          <ul>
            {membersWithoutWaiver.map((item: any) => {
              return (
                <li key={item.custId}>
                  {item.fName} {item.lName}
                </li>
              );
            })}
          </ul>
          <p>- Continue to complete the waiver, or return to change your selection</p>
        </div>
      </Confirm>

      {prompts && (
        <Prompts
          setQty={setQty}
          setPromptTempMembers={setPromptTempMembers}
          setPrompts={setPrompts}
          handleContinueClick={async idx => {
            const uncheckedPrompts = prompts.findIndex(item => item.disabledContinue);
            if (uncheckedPrompts !== -1) {
              const newPromptsData = update(prompts, {
                [idx]: { isOpen: { $set: false } },
                [uncheckedPrompts]: { isOpen: { $set: true } },
              });
              setPrompts(newPromptsData);
            } else {
              setPrompts(null);
              setLoading(true);
              await addToCart(promptTempMembers);
              setLoading(false);
            }
          }}
          prompts={prompts}
        />
      )}

      <WarningAlert
        isOpen={notEnoughTimeSlots}
        title="Sorry"
        handleClick={async () => {
          await fetchTimeSlots(
            selectedTimeSlots.map(time => {
              return {
                selectedScheduledDate: time.scheduledTime,
                ...(time.trackNo && { trackNo: time.trackNo }),
              };
            }),
          );
          dispatch(updateStatusNotEnoughTimeSlots({ notEnoughTimeSlots: false }));

          setShowListOfGuests(false);
          dispatch(hideSelectionOfParticipant());
        }}
        description="It looks like there are no longer enough timeslots for the number of guests you’ve selected. Please pick another product or another timeslot."
      />

      {membershipStore.customers && (
        <ProductMembershipRequired
          closeChooseParticipant={() => {
            setShowListOfGuests(false);
            dispatch(hideSelectionOfParticipant());
          }}
          chooseParticipant={() => {
            setShowListOfGuests(true);
            dispatch(
              showSelectionOfParticipant({
                checkId,
                productId: productDetail.productId,
                scheduledTime: selectedTimeSlots.map(time => time.scheduledTime),
              }),
            );
          }}
        />
      )}
      <IdleMonitor />
    </>
  );
};

export default ProductScreen;
