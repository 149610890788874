import validator from 'validator';
import * as Api from '../api';
import moment from 'moment';
import { isIpAddress } from './core';

const fieldValidator = async (field: string, fieldValue: any, required: boolean, allowDuplicateEmail: boolean) => {
  try {
    switch (field) {
      case 'cvv2':
        if (required && !fieldValue) {
          throw 'Please enter cvv code';
        }
        break;
      case 'postal':
        if (required && !fieldValue) {
          throw 'Please enter postal code';
        }
        break;
      case 'birthDate':
        if (fieldValue == null || fieldValue.length !== 10) {
          throw 'Please provide a date of birth';
        }
        if (!moment(moment(fieldValue).format('MM/DD/YYYY'), 'MM/DD/YYYY', true).isValid()) {
          throw 'Please provide a MM/DD/YYYY format';
        }

        var now = moment();

        if (now <= moment(moment(fieldValue).format('MM/DD/YYYY'), 'MM/DD/YYYY', true)) {
          throw 'Please provide a date in the past with a  MM/DD/YYYY format';
        }
        var minAllowedDate = moment('1900-01-01');
        if (minAllowedDate > moment(moment(fieldValue).format('MM/DD/YYYY'), 'MM/DD/YYYY', true)) {
          throw 'Please provide a valid MM/DD/YYYY format';
        }

        break;
      case 'fName':
        if (required && !fieldValue.trim()) {
          throw 'Please enter first name';
        }
        if (!/^[a-zA-Z0-9\s]+$/.test(fieldValue)) {
          throw 'Please enter a valid first name';
        }
        if (fieldValue.length > 30) {
          throw 'First name can\'t be longer than 30 characters.';
        }
        break;
      case 'lName':
        if (required && !fieldValue.trim()) {
          throw 'Please enter last name';
        }
        if (!/^[a-zA-Z0-9\s]+$/.test(fieldValue)) {
          throw 'Please enter a valid last name';
        }
        if (fieldValue.length > 30) {
          throw 'Last name can\'t be longer than 30 characters.';
        }
        break;
      case 'gender':
        if (required && !fieldValue) {
          throw 'Error';
        }
        break;
      case 'racerName':
        if (required && !fieldValue) {
          throw 'Please enter nickname';
        }
        break;
      case 'address':
        if (required && !fieldValue) {
          throw 'Please enter an address';
        }
        break;
      case 'city':
        if (required && !fieldValue) {
          throw 'Please enter city';
        }
        break;
      case 'state':
        if (required && !fieldValue) {
          throw 'State is a required field';
        }
        break;
      case 'country':
        if (required && !fieldValue) {
          throw 'Error';
        }
        break;
      case 'zip':
        if (required && !fieldValue) {
          throw 'Please enter postal code';
        }
        break;
      case 'licenseNumber':
        if (required && !fieldValue) {
          throw 'Please enter license number';
        }
        break;
      case 'emailAddress':
        if (required && !fieldValue) {
          throw 'Please enter an email address';
        }
        if (!!fieldValue && !validator.isEmail(fieldValue)) {
          throw 'Email is not valid';
        }
        try {
          if (!allowDuplicateEmail) {
            const { data } = await Api.checkCustomerEmailAddress(fieldValue);
            if (!data.success) {
              throw `${data.message}`;
            }
          }
        } catch (e) {
          throw new Error(e);
        }
        break;
      case 'sourceId':
        if (required && !fieldValue) {
          throw 'Please make a selection';
        }
        break;
      case 'custom1':
        if (required && !fieldValue) {
          throw 'Error';
        }
        break;
      case 'custom2':
        if (required && !fieldValue) {
          throw 'Error';
        }
        break;
      case 'custom3':
        if (required && !fieldValue) {
          throw 'Error';
        }
        break;
      case 'relationships':
        if (required && !fieldValue) {
          throw 'Please make a selection';
        }
        break;
      case 'terminalNumber':
        if (required && !fieldValue) {
          throw 'Please select terminal number';
        }
        break;
      case 'Check-In':
        if (required && !fieldValue.onlineBooking && !fieldValue.event && !fieldValue.eventList) {
          throw 'Please select any Check-In';
        }
        break;
      case 'sessionTimeout':
        if (required && !fieldValue) {
          throw 'Please enter session timeout';
        }
        if (isNaN(Number(fieldValue))) {
          throw 'Session timeout not is numeric';
        }
        if (Number(fieldValue) < 2) {
          throw 'Please enter session timeout more than 1 minute';
        }
        break;
      case 'password':
        if (required && !fieldValue) {
          throw 'Error';
        }
        break;
      case 'terminalIp':
      case 'ip':
        if (required && !fieldValue) {
          throw 'Error. Please enter IP Address';
        }
        if (isIpAddress(fieldValue)) {
          break;
        } else {
          throw 'IP address invalid';
        }
    }
  } catch (e) {
    throw new Error(e);
  }
};

export default fieldValidator;
