import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import { createCartWorker } from './createCartSaga';
import { ADD_ITEM_TO_CART_TYPES, addItemToCartActionFailure, addItemToCartActionSuccess } from '../actions';

import { updateStatusNotEnoughTimeSlots } from '../../PurchaseFlow/actions';

import { PRODUCT_TYPE, ROUTES } from '../../../constants';
import { history } from '../../../App';
import { membershipRequestAction } from '../../Membership/actions';

export function* addItemToCartWorker({ payload }: any) {
  const { productType, item, isRequiredMembership, isSchedulable, checkDetails } = payload;
  try {
    const { customerStore, cartStore } = yield select(store => store);
    let checkId = cartStore.checkId;
    let customersMembership = [];
    if (!checkId) {
      checkId = yield call<any>(createCartWorker);
    }

    if (isRequiredMembership) {
      if (!item?.isSchedulable && !isSchedulable && item) {
        const {
          data: { customerMemberships },
        } = yield call<any>(Api.validateCustomerGroupMembership, {
          checkId,
          productId: item.productId,
          customerId: customerStore.custId,
        });
        customersMembership = customerMemberships;
      } else {
        let productsCustomers;
        if (checkDetails) {
          productsCustomers = checkDetails.map((product): any => {
            return { productId: product.productId, customerId: product.custId };
          });
        } else {
          productsCustomers = [{ productId: item.productId, customerId: customerStore.custId }];
        }

        const {
          data: { customerMemberships },
        } = yield call<any>(Api.validateCustomerMembership, {
          checkId,
          productsCustomers,
        });
        customersMembership = customerMemberships;
      }
    }

    const hasMembership = customersMembership.filter((customer): any => !customer.customerHasMembership);
    if (!hasMembership.length) {
      const payloadData = {
        checkId,
        checkDetails: [],
      };

      if (
        (productType === PRODUCT_TYPE.REGULAR ||
          productType === PRODUCT_TYPE.FOOD ||
          productType === PRODUCT_TYPE.GIFT_CARD ||
          productType === PRODUCT_TYPE.INTER_CARD) &&
        !payload.checkDetails
      ) {
        payloadData.checkDetails.push({
          ...item,
          ...(customersMembership.length &&
            customersMembership[0]?.customerId === customerStore.custId && {
              membershipProductId: customersMembership[0]?.membershipProducts[0]?.membershipProductId,
            }),
          ...(customerStore.custId && { custId: customerStore.custId }),
        });
      }

      if (productType === PRODUCT_TYPE.POINT || productType === PRODUCT_TYPE.MEMBERSHIP || payload.checkDetails) {
        if (customersMembership.length) {
          const hasMembershipCheckDetails = payload.checkDetails.map((product): any => {
            return {
              ...product,
              membershipProductId: customersMembership.filter((membership): any => {
                return membership.customerId === product.custId;
              })[0]?.membershipProducts[0]?.membershipProductId,
            };
          });
          if (hasMembershipCheckDetails) {
            payloadData.checkDetails = hasMembershipCheckDetails;
          }
        } else {
          payloadData.checkDetails = payload.checkDetails;
        }
      }

      const { data } = yield call<any>(Api.addToCart, payloadData);

      if (data.errorCode === -1) {
        yield put<any>(updateStatusNotEnoughTimeSlots({ notEnoughTimeSlots: true }));
      }
      if (!data.errorCode) {
        yield put<any>(addItemToCartActionSuccess(data));
        history.replace(ROUTES.PRODUCTS);
      }
    } else {
      let product;
      if (payload.checkDetails) {
        product = { productType, isSchedulable, items: [...payload.checkDetails] };
      } else {
        product = {
          productType,
          isSchedulable,
          items: [{ ...item, ...(customerStore.custId && { custId: customerStore.custId }) }],
        };
      }
      yield put<any>(
        membershipRequestAction({
          hasMembership,
          product,
        }),
      );
      yield put<any>(addItemToCartActionFailure());
    }
  } catch (err) {
    yield put<any>(addItemToCartActionFailure());
  }
}

export default function* addItemToCartSaga() {
  yield all([yield takeLatest(ADD_ITEM_TO_CART_TYPES.REQUEST, addItemToCartWorker)]);
}
