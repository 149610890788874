import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Api from '../../api';
import { history } from '../../App';
import { sha256 } from '../../utils/bcrypt';
import { confirmationCodeCheckReset, startResetPinAction } from '../../store/Customer/actions';
import IdleMonitor from '../../services/IdleMonitor';
import Button from '../../components/Button';
import PhoneKeypad from '../../components/PhoneKeypad';
import styles from './styles.module.scss';
import ContentContainer from '../../components/ContentContainer';
import { ROUTES } from '../../constants';
import WarningAlert from '../../components/WarningAlert';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import cn from 'classnames';
import HeaderPanel from '../../components/HeaderPanel';
import { nativeKeyboardStateSelector, terminalModeStateSelector } from '../../store/Settings/selectors';
import { isCheckInMode, isFoodAndActivitiesMode } from '../../utils/detectTerminalMode';
import { addGuestInEventAction } from '../../store/Customer/actions/eventsActions';

type formType = {
  [key: string]: string;
};

const LogInSecondStepScreen = () => {
  const dispatch = useDispatch();
  const {
    customerStore,
    resetPinStore,
    events,
    assignActivitiesReducer,
    confirmationCodeReducer,
    terminalSettings: { checkInType },
  } = useSelector(state => state);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const terminalMode = useSelector(terminalModeStateSelector);

  const [activeField, setActiveField] = useState('pin');
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({
    pin: '',
  });
  const [pinErrorMessage, setPinErrorMessage] = useState('');
  const [pinExists, setPinExists] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!customerStore.pinExists) {
      setPinExists(false);
    }
  }, []);

  const phoneKeypadHandler = (newValueOfField: string) => {
    if (newValueOfField.length <= 4) {
      setPinErrorMessage('');
      setFieldsOfForm({ ...fieldsOfForm, [activeField]: newValueOfField });
    }
  };

  const addGuestToEvent = async () => {
    try {
      if (events.selectedEvent && customerStore?.custId) {
        dispatch(
          addGuestInEventAction({
            eventId: events.selectedEvent.eventID,
            custId: String(customerStore.custId),
          }),
        );
      }

      if (confirmationCodeReducer?.success) {
        dispatch(
          addGuestInEventAction({
            eventId: confirmationCodeReducer?.data?.eventId,
            custId: String(customerStore?.custId),
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const logIn = async () => {
    if (!validateFieldsOfForm()) return;
    try {
      setLoading(true);
      const hashPin = await sha256(fieldsOfForm.pin);
      const { data } = await Api.verifyPinCustomer(customerStore.custId, hashPin);
      if (data.success) {
        setLoading(false);

        if (confirmationCodeReducer?.isEventList || events?.selectedEvent || confirmationCodeReducer?.data?.eventId) {
          addGuestToEvent();
        } else {
          history.replace(
            confirmationCodeReducer?.success && checkInType?.onlineBooking
              ? ROUTES.ASSIGN_ACTIVITIES
              : ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE,
          );
        }
        return;
      }
      fieldsOfForm.pin = '';
      setLoading(false);
      setPinErrorMessage(data.message);
      return;
    } catch (e) {
      console.log(e);
    }
  };

  const resetPin = () => {
    dispatch(startResetPinAction({ customerData: customerStore }));
  };

  const validateFieldsOfForm = () => {
    if (fieldsOfForm.pin === '') {
      setPinErrorMessage('Enter a 4 digit pin');
      return false;
    }
    setPinErrorMessage('');

    return true;
  };

  const goToCreateNewPin = () => {
    setPinExists(true);
    history.push(ROUTES.LOG_IN_ANOTHER_WAY_ENTER_PIN, { prevRoute: 'LogInSecondStepScreen' });
  };

  return (
    <Fragment>
      <ContentContainer loading={loading || resetPinStore.loading || customerStore.loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div className={styles.rowMargin}>
              <TextFieldWithLabel
                type="password"
                value={fieldsOfForm.pin}
                placeholder=""
                readOnly={!nativeKeyboardState}
                label="Enter 4 Digit Pin"
                invalid={pinErrorMessage.length > 0}
                errorMessage={pinErrorMessage}
                onFocus={() => setActiveField('pin')}
              />
            </div>
            <div className={styles.rowMargin}>
              <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler} />
            </div>

            <div className={styles.navigation}>
              <Button
                theme={'white'}
                handleClick={resetPin}
                customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
              >
                Forgot Pin
              </Button>
              <div className={styles.navigationFilter} />
              <Button
                theme="red"
                handleClick={logIn}
                customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </ContentContainer>
      <WarningAlert
        isOpen={!pinExists}
        title="Almost There!"
        description="We’ve found your account, please create a PIN before proceeding"
        handleClick={goToCreateNewPin}
      />
      <WarningAlert
        isOpen={!events.eventExists && !!events.errorMessage}
        title=""
        description={events.errorMessage}
        handleClick={() => history.replace(ROUTES.ROOT)}
      />
      <IdleMonitor />
    </Fragment>
  );
};

export default LogInSecondStepScreen;
