import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getCurrentDayEvents, checkIfEventExists, addGuestInEvent } from '../../../api';
import { history } from '../../../App';
import { ROUTES } from '../../../constants';

import { getEventsSuccessfully, getEventsFailure, EVENTS } from '../actions';
import { selectEvent } from '../actions/eventsActions';

type props = {
  isEventList: boolean;
};

export function* getCurrentDayEventsSaga() {
  try {
    const { data }: { data: any } = yield call(getCurrentDayEvents);
    yield put(getEventsSuccessfully(data));
  } catch (err) {
    console.log(err);
  }
}

type Props = {
  payload: string;
  type: string;
};

export function* checkIfEventExistsSaga({ payload }: Props) {
  try {
    const { data }: { data: any } = yield call(checkIfEventExists, {
      eventId: payload,
    });
    yield put(selectEvent(data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* addGuestInEventSaga({ payload }: any) {
  try {
    const { data }: { data: any } = yield call(addGuestInEvent, {
      eventId: payload.eventId,
      custId: payload.custId,
    });

    yield put(
      data.success
        ? getEventsSuccessfully({
            ...data,
            confirmationNumber: payload,
          })
        : getEventsFailure({
            message: data.message,
          }),
    );

    if (data.success) {
      history.replace(ROUTES.CONGRATULATIONS);
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* eventsSaga() {
  yield takeLatest(EVENTS.GET_CURRENT_DAY_EVENTS, getCurrentDayEventsSaga);
  yield takeLatest(EVENTS.CHECK_IF_EVENT_EXIST, checkIfEventExistsSaga);
  yield takeLatest(EVENTS.ADD_GUEST_IN_EVENT, addGuestInEventSaga);
}
