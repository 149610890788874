export const GET_PRODUCT_CATEGORIES_TYPES = {
  REQUEST: 'productCategories/GET_PRODUCT_CATEGORIES_REQUEST',
  SUCCESS: 'productCategories/GET_PRODUCT_CATEGORIES_SUCCESS',
  FAILURE: 'productCategories/GET_PRODUCT_CATEGORIES_FAILURE',
};

export const getProductCategoriesAction = payload => ({
  type: GET_PRODUCT_CATEGORIES_TYPES.REQUEST,
  payload,
});

export const getProductCategoriesActionSuccess = (payload: any) => ({
  type: GET_PRODUCT_CATEGORIES_TYPES.SUCCESS,
  payload,
});

export const getProductCategoriesActionFailure = () => ({
  type: GET_PRODUCT_CATEGORIES_TYPES.FAILURE,
});
