import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import {
  startCustomerSessionAction,
  startCustomerSessionForFunAction,
  startCustomerSessionForFoodAction,
  startCustomerSessionForReservationCheckInAction,
} from '../../store/Terminal/actions';
import { endCustomerSessionAction } from '../../store/General/actions';
import { checkKioskConfiguration, getRegistrationSettingsAction } from '../../store/Settings/actions';
import { terminalModeStateSelector, terminalSettingsSelector } from '../../store/Settings/selectors';
import { getSourcesAction } from '../../store/Source/actions';
import { terminalCheckInTypeStateSelector } from '../../store/Settings/selectors/terminalSettingsSelector';

import ContentContainer from '../../components/ContentContainer';
import Logo from '../../components/Logo';
import NavigationButton from '../../components/NavigationButton';

import { history } from '../../App';
import { ROUTES, TERMINAL_MODES } from '../../constants';

import {
  isDefaultMode,
  isFoodMode,
  isFoodAndActivitiesMode,
  isRegistrationMode,
  isCheckInMode,
} from '../../utils/detectTerminalMode';

import modeFoodIcon from '../../assets/icons/mode_food.svg';
import modeFoodAndActivitiesIcon from '../../assets/icons/mode_tickets_and_food.svg';
import checkInIcon from '../../assets/icons/check-in.svg';

import styles from './styles.module.scss';
import { confirmationCodeCheckReset } from '../../store/Customer/actions';
import { getActivitiesByConfirmationCodeReset } from '../../store/Customer/actions/assignActivitiesActions';
import { resetEvent } from '../../store/Customer/actions/eventsActions';

const WelcomeScreen = () => {
  const dispatch = useDispatch();
  let timer;
  const checkInType = useSelector(terminalCheckInTypeStateSelector);
  const terminalMode = useSelector(terminalModeStateSelector);
  const terminalSettings = useSelector(terminalSettingsSelector);

  const { registrationSettings, source } = useSelector(state => state);
  const goToSettings = () => {
    timer = setTimeout(() => history.push(ROUTES.LOGIN_SETTINGS), 10000);
  };

  const resetTimer = () => {
    clearTimeout(timer);
  };

  React.useEffect(() => {
    // Reset Selected Action
    dispatch(resetEvent());

    localStorage.setItem('login_another_way_birthdate', '');
    localStorage.setItem('login_another_way_fName', '');
    localStorage.setItem('login_another_way_lName', '');

    dispatch(endCustomerSessionAction());
    dispatch(getRegistrationSettingsAction());
    dispatch(getSourcesAction());

    if (isCheckInMode(terminalMode) && checkInType.eventList) {
      dispatch(
        checkKioskConfiguration({
          kioskMode: terminalMode,
          terminalName: terminalSettings.terminalNumber,
          isEnabledOnlineBookingCheckin: checkInType.onlineBooking,
          isEnabledEventCheckIn: checkInType.event,
          isEnabledEventListCkeckIn: checkInType.eventList,
        }),
      );
    }
  }, []);

  const startCustomerSession = mode => {
    dispatch(startCustomerSessionAction(mode));
  };

  const startCustomerSessionForFun = mode => {
    dispatch(
      startCustomerSessionForFunAction({
        mode,
        checkInType,
      }),
    );
  };

  const startCustomerSessionForReservationCheckIn = checkInType => {
    dispatch(startCustomerSessionForReservationCheckInAction(checkInType));
  };

  const onTabToBegin = () => {
    if (isDefaultMode(terminalMode)) {
      startCustomerSessionForFun(terminalMode);
    }

    if (isFoodMode(terminalMode)) {
      startCustomerSession(terminalMode);
    }

    if (isRegistrationMode(terminalMode)) {
      startCustomerSession(terminalMode);
    }

    if (isCheckInMode(terminalMode)) {
      startCustomerSessionForReservationCheckIn(checkInType);
    }
  };

  React.useEffect(() => {
    dispatch(confirmationCodeCheckReset());
    dispatch(getActivitiesByConfirmationCodeReset());
  }, []);

  return (
    <ContentContainer loading={registrationSettings.loading || source.loading} theme="red">
      <div className={styles.container} onMouseUp={onTabToBegin}>
        <div>
          <div className={styles.logoWrapper}>
            <button
              className={styles.logoButton}
              onMouseDown={goToSettings}
              onMouseUp={resetTimer}
              onTouchStart={goToSettings}
              onTouchEnd={resetTimer}
            >
              <Logo width="700" />
            </button>
          </div>
          <h2 className={cn(styles.title, { [styles.titleLight]: isFoodAndActivitiesMode(terminalMode) })}>
            Self-Serve Kiosk
          </h2>
          {isFoodAndActivitiesMode(terminalMode) && <h3 className={styles.subTitle}>What are you here for?</h3>}
          {!isFoodAndActivitiesMode(terminalMode) ? (
            <div className={styles.titleWrapper}>
              <h2>Tap to {terminalMode === 'reservationCheckIn' ? 'Check-In' : 'begin'}</h2>
            </div>
          ) : null}
          {isFoodAndActivitiesMode(terminalMode) && (
            <div className={styles.navigation}>
              <NavigationButton
                icon={modeFoodIcon}
                label={'I want\nfood'}
                customClass={styles.navigationButton}
                onClick={() => startCustomerSession(TERMINAL_MODES.food.value)}
              />
              <NavigationButton
                icon={modeFoodAndActivitiesIcon}
                label={'I want\nfun'}
                customClass={styles.navigationButton}
                onClick={() => startCustomerSessionForFun(TERMINAL_MODES.foodAndActivities.value)}
              />
              {TERMINAL_MODES.reservationCheckIn.value === terminalMode && (
                <NavigationButton
                  icon={checkInIcon}
                  label={'reservation\ncheck-in'}
                  customClass={styles.navigationButton}
                  onClick={() => startCustomerSessionForReservationCheckIn(TERMINAL_MODES.reservationCheckIn.value)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default WelcomeScreen;
