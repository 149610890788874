export const GET_CUSTOMER_RELATIONSHIPS_TYPES = {
  REQUEST: 'customerGroup/GET_CUSTOMER_RELATIONSHIPS_REQUEST',
  SUCCESS: 'customerGroup/GET_CUSTOMER_RELATIONSHIPS_SUCCESS',
  FAILURE: 'customerGroup/GET_CUSTOMER_RELATIONSHIPS_FAILURE',
};

export const getCustomerRelationshipsAction = (payload: any) => ({
  type: GET_CUSTOMER_RELATIONSHIPS_TYPES.REQUEST,
  payload,
});

export const getCustomerRelationshipsActionSuccess = (payload: any) => ({
  type: GET_CUSTOMER_RELATIONSHIPS_TYPES.SUCCESS,
  payload,
});

export const getCustomerRelationshipsActionFailure = () => ({
  type: GET_CUSTOMER_RELATIONSHIPS_TYPES.FAILURE,
});
