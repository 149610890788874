import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import IdleMonitor from '../../services/IdleMonitor';
import Button from '../../components/Button';
import cn from 'classnames';
import { history } from '../../App';
import {
  verifyActivityByConfrimationNumberAction,
  verifyEventByConfrimationNumberAction,
  verifyConfirmationCodeForEventAndActivity,
  confirmationCodeCheckReset,
  confirmationCodeSuccessForBoth,
} from '../../store/Customer/actions/confirmationCodeActions';
import { terminalModeStateSelector } from '../../store/Settings/selectors';
import { ConfirmationCodeCheckResponse } from '../../store/Customer/types/confirmationCode';
import { startCustomerSessionAction } from '../../store/Terminal/actions';
import { TERMINAL_MODES } from '../../constants';
import { checkIfEventExistsAction, resetEvent } from '../../store/Customer/actions/eventsActions';
import { isCheckInMode } from '../../utils/detectTerminalMode';

import eventComplete from '../../assets/icons/completed-event.svg';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';
import Modal from '../../components/Modal';
import Keyboard from '../../components/Keyboard';
import Confirm from '../../components/Prompt';

const ERROR_MESSAGE = 'Confirmation number not found';

const ReservationCheckIn = () => {
  const terminalMode = useSelector(terminalModeStateSelector);
  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const {
    confirmationCodeReducer,
    terminalSettings: { checkInType },
  } = useSelector(state => state);
  const event = useSelector(state => state.events);
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);

  const onKeyboardPress = (newFieldValue: string, fieldName) => {
    setValue(newFieldValue);
  };
  const [showModal, setShowModal] = useState(false);

  const handleNext = () => {
    if (!value) {
      setError('Please enter confirmation code');
    } else {
      if (checkInType.event && checkInType.onlineBooking) {
        dispatch(verifyConfirmationCodeForEventAndActivity(value));
        return;
      }

      if (checkInType.event || checkInType?.eventList) {
        dispatch(verifyEventByConfrimationNumberAction(value));
      } else {
        dispatch(verifyActivityByConfrimationNumberAction(value));
      }
    }
  };

  const handleContinue = () => {
    dispatch(startCustomerSessionAction(TERMINAL_MODES.foodAndActivities.value));
  };

  useEffect(() => {
    dispatch(resetEvent());
    dispatch(confirmationCodeCheckReset());
    setError('');
  }, []);

  useEffect(() => {
    if (confirmationCodeReducer && !confirmationCodeReducer.success && !confirmationCodeReducer.pending) {
      if (ERROR_MESSAGE === confirmationCodeReducer?.errorMessage) {
        setError(confirmationCodeReducer?.errorMessage);
      } else {
        setShowModal(true);
      }
    }

    if (
      ((confirmationCodeReducer && confirmationCodeReducer.success && !confirmationCodeReducer.pending) ||
        event.eventExists) &&
      !confirmationCodeReducer?.isValidForBoth
    ) {
      setStep(2);
    }
  }, [confirmationCodeReducer]);

  return (
    <>
      {step === 1 ? (
        <ContentContainer loading={confirmationCodeReducer?.pending}>
          <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />
          <div className={styles.wizardFormField}>
            <div className={styles.headerWrapper}>
              <h1>Enter Your Confirmation Code and Complete Registration</h1>
            </div>
            <div className={styles.wizardFormFieldInner}>
              <div className={styles.rowMargin}>
                <TextFieldWithLabel
                  type="text"
                  // inputMode={nativeKeyboardState}
                  required={true}
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  placeholder=""
                  label="Confirmation Code"
                  invalid={Boolean(error)}
                  errorMessage={error}
                />
              </div>
              {!nativeKeyboardState && (
                <div className={styles.keyboard}>
                  <div>
                    <Keyboard
                      // fieldName={activeField}
                      valueOfField={value}
                      onChange={onKeyboardPress}
                      hideKeyboard={() => {}}
                      handleEnter={() => {}}
                    />
                  </div>
                </div>
              )}
              <div className={styles.navigation}>
                <Button
                  theme={'white'}
                  handleClick={() => history.push('/')}
                  customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
                >
                  Back
                </Button>
                <div className={styles.navigationFilter} />
                <Button
                  theme="red"
                  handleClick={handleNext}
                  customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
                >
                  continue
                </Button>
              </div>
            </div>
          </div>
          <IdleMonitor />
        </ContentContainer>
      ) : (
        <div className={styles.screenWrapper}>
          <div className={styles.contentWrapper}>
            <div>
              <h1>We Found Your {confirmationCodeReducer?.data?.eventId ? 'Event' : 'Reservation'}!</h1>
              <h2>Please Complete Registration.</h2>
            </div>
            <div className={styles.imageWrapper}>
              <img src={eventComplete} alt="event-complete" className={styles.image} />
            </div>
            <div>
              <Button theme="white" handleClick={handleContinue} customClass={styles.continueButton}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={showModal}>
        <div className={styles.container}>
          <h2 className={styles.title}>{confirmationCodeReducer?.errorMessage?.title}</h2>
          <div className={styles.description}>
            <p>{confirmationCodeReducer?.errorMessage?.body}</p>
          </div>
          <div className={styles.description}>
            <p>{confirmationCodeReducer?.errorMessage?.footer}</p>
          </div>
          <div className={styles.footer}>
            <Button
              theme="red"
              customClass={styles.button}
              handleClick={() => {
                history.push('/');
              }}
            >
              Start Over
            </Button>
          </div>
        </div>
      </Modal>
      <Confirm
        isOpen={confirmationCodeReducer?.isValidForBoth}
        titleButtonCancel="No"
        titleButtonOk="Yes"
        handleReturnClick={() => {
          dispatch(
            confirmationCodeSuccessForBoth({
              isEvent: true,
            }),
          );
        }}
        handleContinueClick={() => {
          dispatch(
            confirmationCodeSuccessForBoth({
              isEvent: false,
            }),
          );
        }}
      >
        <div className={styles.confirmModalBody}>
          <h1>Did you book online?</h1>
        </div>
      </Confirm>
    </>
  );
};

export default ReservationCheckIn;
