export const NEW_CUSTOMER_TYPES = {
  UPDATE_COMMON_DATA: 'customer/UPDATE_COMMON_DATA',
  SAVE_NEW_CUSTOMER_DATA: 'customer/SAVE_NEW_CUSTOMER_DATA',
  UPDATE_SIGNATURE: 'customer/UPDATE_SIGNATURE',

  CREATE_NEW_CUSTOMER_REQUEST: 'customer/CREATE_NEW_CUSTOMER_REQUEST',
  CREATE_NEW_CUSTOMER_SUCCESS: 'customer/CREATE_NEW_CUSTOMER_SUCCESS',
  CREATE_NEW_CUSTOMER_FAILURE: 'customer/CREATE_NEW_CUSTOMER_FAILURE',
};

export const updateCommonDataOfNewCustomerAction = (payload: any) => ({
  type: NEW_CUSTOMER_TYPES.UPDATE_COMMON_DATA,
  payload,
});

export const updateSignatureOfNewCustomerAction = (payload: any) => ({
  type: NEW_CUSTOMER_TYPES.UPDATE_SIGNATURE,
  payload,
});

export const saveNewCustomerDataAction = (payload: any) => ({
  type: NEW_CUSTOMER_TYPES.SAVE_NEW_CUSTOMER_DATA,
  payload,
});

export const createNewCustomerAction = (payload: any) => ({
  type: NEW_CUSTOMER_TYPES.CREATE_NEW_CUSTOMER_REQUEST,
  payload,
});

export const createNewCustomerActionSuccess = (payload: any) => ({
  type: NEW_CUSTOMER_TYPES.CREATE_NEW_CUSTOMER_SUCCESS,
  payload,
});

export const createNewCustomerActionFailure = () => ({
  type: NEW_CUSTOMER_TYPES.CREATE_NEW_CUSTOMER_FAILURE,
});
