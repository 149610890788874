import {
  ConfirmationCodeCheckPayload,
  ConfirmationCodeCheckResponse,
  ChosenConfirmationCodePayload,
} from '../types/confirmationCode';

export const CONFIRMATION_CODE_TYPES = {
  VERIFY_CONFIRMATION_CODE_FOR_EVENT_AND_ACTIVITY:
    'customer/confirmationCode/VERIFY_CONFIRMATION_CODE_FOR_EVENT_AND_ACTIVITY',
  CONFIRMATION_CODE_CHECK_SUCCESSFUL_FOR_EVENT_AND_ACTIVITY:
    'CONFIRMATION_CODE_CHECK_SUCCESSFUL_FOR_EVENT_AND_ACTIVITY',
  VERIFY_EVENT_BY_CONFIRMATION_CODE: 'eventHeats/confirmationCode/CONFIRMATION_CODE_CHECK_REQUEST',
  VERIFY_ACTIVITY_BY_CONFIRMATION_CODE: 'customer/confirmationCode/VERIFY_ACTIVITY_BY_CONFIRMATION_CODE',
  CONFIRMATION_CODE_CHECK_SUCCESS: 'customer/confirmationCode/CONFIRMATION_CODE_CHECK_SUCCESS',
  CONFIRMATION_CODE_CHECK_FAILURE: 'customer/confirmationCode/CONFIRMATION_CODE_CHECK_FAILURE',
  CONFIRMATION_CODE_CHECK_RESET: 'customer/confirmationCode/CONFIRMATION_CODE_CHECK_RESET',
  CONFIRMATION_CODE_CHECK_STARTED: 'customer/confirmationCode/CONFIRMATION_CODE_CHECK_STARTED',
};

export const verifyEventByConfrimationNumberAction = (payload: ConfirmationCodeCheckPayload) => ({
  type: CONFIRMATION_CODE_TYPES.VERIFY_EVENT_BY_CONFIRMATION_CODE,
  payload,
});

export const verifyActivityByConfrimationNumberAction = (payload: ConfirmationCodeCheckPayload) => ({
  type: CONFIRMATION_CODE_TYPES.VERIFY_ACTIVITY_BY_CONFIRMATION_CODE,
  payload,
});

export const confirmationCodeCheckSuccess = (payload: ConfirmationCodeCheckResponse) => ({
  type: CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_SUCCESS,
  payload,
});

export const confirmationCodeCheckFailure = (payload: any) => ({
  type: CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_FAILURE,
  payload,
});

export const confirmationCodeCheckReset = () => ({
  type: CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_RESET,
});

export const verifyConfirmationCodeForEventAndActivity = (payload: ConfirmationCodeCheckPayload) => ({
  type: CONFIRMATION_CODE_TYPES.VERIFY_CONFIRMATION_CODE_FOR_EVENT_AND_ACTIVITY,
  payload,
});

export const confirmationCodeSuccessForBoth = (payload: ChosenConfirmationCodePayload) => ({
  type: CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_SUCCESSFUL_FOR_EVENT_AND_ACTIVITY,
  payload,
});
