export interface Product {
  productId: number;
  productType: number;
  description: string;
  price1: number;
  price2: number;
  price3: number;
  price4: number;
  price5: number;
  taxId: number;
  productClassId: number;
  largeIcon: string;
  isSpecial: boolean;
  availableDay: string;
  availableFromTime: Date;
  availableToTime: Date;
  isRequiredMembership: boolean;
  showOnWeb: boolean;
  isTrackable: boolean;
  isShowStat: boolean;
  isInventory: boolean;
  cost: number;
  req: number;
  vendorId: number;
  enabled: boolean;
  deleted: boolean;
  p_PointTypeId: number;
  p_Points: number;
  bonusValue: number;
  paidValue: number;
  comValue: number;
  entitle1: number;
  entitle2: number;
  entitle3: number;
  entitle4: number;
  entitle5: number;
  entitle6: number;
  entitle7: number;
  entitle8: number;
  m_Points: number;
  m_MembershiptypeId: number;
  r_Points: number;
  r_LocalOnly: boolean;
  g_Points: number;
  s_SaleBy: number;
  s_Vol: number;
  s_NoOfLapsOrSeconds: number;
  s_CustId: number;
  priceCadet: number;
  dateCreated: Date;
  webShop: boolean;
  isAlcohol: boolean;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  sku: string;
  isCadet: boolean;
  showInSelfServeKiosk: boolean;
  highResImagePath: string;
}

export enum ProductCategoriesScreenModes {
  FOOD = 'food',
  ACTIVITY = 'activity',
}
