import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../App';
import * as Api from '../../api';
import ROUTES from '../../constants/routes';
import HeaderPanel from '../../components/HeaderPanel';
import styles from './styles.module.scss';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import MemberCard from '../../components/MemberCard';
import Button from '../../components/Button';
import Title from '../../components/Title';
import AddGuestForm from '../../components/AddGuestForm';
import { getCustomerGroupAction } from '../../store/CustomerGroup/actions';
import Confirm from '../../components/Prompt';
import IconWithText from '../../components/IconWithText';
import addNewButton from '../../assets/icons/add-new-button.svg';

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const { customerStore, customerGroup } = useSelector(state => state);
  const [showAddGuest, setShowAddGuest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [membersList, setMembersList] = useState<any>([]);
  const [heightOfContainer, setHeightOfContainer] = useState<any>(0);
  const [memberForRemoving, setMemberForRemoving] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    setMembersList([customerStore, ...customerGroup.members]);
  }, [customerGroup]);

  useEffect(() => {
    handleResizeContainer();
    window.addEventListener('resize', handleResizeContainer);
    return () => {
      window.removeEventListener('resize', handleResizeContainer);
    };
  }, []);

  const handleResizeContainer = () => {
    const windowHeight = window.innerHeight;
    const headerPanelHeight = document.getElementById('headerPanel').offsetHeight;
    const titlePanelHeight = document.getElementById('panelTitle').offsetHeight;
    setHeightOfContainer(windowHeight - (headerPanelHeight + titlePanelHeight));
  };

  const removeFromGroup = custId => {
    setMemberForRemoving(custId);
    setShowPrompt(true);
  };

  const confirmRemoveFromGroup = async () => {
    try {
      setShowPrompt(false);
      setLoading(true);
      await Api.removeFromCustomerGroup({
        custIds: [memberForRemoving],
        createdById: customerStore.custId,
      });
      await dispatch(getCustomerGroupAction({ custId: customerStore.custId }));
      setLoading(false);
    } catch (e) {
      setShowPrompt(false);
      setLoading(false);
    }
  };

  const cancelRemoveFromGroup = () => {
    setMemberForRemoving(null);
    setShowPrompt(false);
  };
  const goBack = () => {
    history.push(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
  };

  return (
    <Fragment>
      <ContentContainer loading={loading}>
        <HeaderPanel homeButton logOutButton border />
        <Title message="Guests" />
        <div className={styles.buttonWrapper}>
          <IconWithText icon={addNewButton} text="ADD MORE GUESTS" color="red" onClick={() => setShowAddGuest(true)} />
        </div>
        {/*<div id="titlePanel" className={styles.titleWrap}>*/}
        {/*  <h2 className={styles.title}>Guests</h2>*/}
        {/*</div>*/}
        <div className={styles.container} style={{ height: `${heightOfContainer}px` }}>
          <div className={styles.membersListContainer}>
            {/*<div className={styles.membersListContainerInner}>*/}
            {membersList.map((item, index) => (
              <MemberCard
                key={index}
                isPrimaryMember={customerStore.custId === item.custId}
                handleClick={() => history.push(`${ROUTES.EDIT_PROFILE}/${item.custId}`)}
                removeHandler={() => removeFromGroup(item.custId)}
                custId={item.custId}
                firstName={item.fName}
                lastName={item.lName}
                birthday={item.birthDate}
                gender={item.gender}
              />
            ))}
            {/*</div>*/}
          </div>
          {showAddGuest && <AddGuestForm endAddingGuestProcessHandler={() => setShowAddGuest(false)} />}
          <div className={styles.navigation}>
            <Button theme="white" handleClick={() => goBack()} customClass={styles.navigationButton}>
              Back
            </Button>
            <div className={styles.navigationFilter} />
          </div>
        </div>
      </ContentContainer>
      <Confirm
        isOpen={showPrompt}
        title="Are you sure?"
        titleButtonCancel="Cancel"
        titleButtonOk="Continue"
        handleReturnClick={() => cancelRemoveFromGroup()}
        handleContinueClick={() => confirmRemoveFromGroup()}
      >
        <div className={styles.confirmRemoveFromGroup}>
          <p>
            Are you sure want to remove this
            <br />
            person from your group?
          </p>
        </div>
      </Confirm>
      <IdleMonitor />
    </Fragment>
  );
};

export default ProfileScreen;
