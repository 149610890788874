import React from 'react';
import Modal from '../Modal';
import Button from '../Button';
import styles from './styles.module.scss';

interface Props {
  isOpen?: boolean;
  title?: string;
  list?: object;
  handleClick?: () => void;
  zIndex?: number;
}

const ListModal = ({ isOpen, title, list, handleClick, zIndex }: Props) => {
  const tableList = Object.entries(list);
  return (
    <Modal isOpen={isOpen} zIndex={zIndex}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.list}>
          {tableList.map(element => (
            <div className={styles.listItem}>
              <h1>{element[0]}</h1>
              <h1>{element[1]}</h1>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <Button theme="red" customClass={styles.button} handleClick={handleClick}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ListModal;
