import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

const ProductItem = ({ handleClick, isActive, highResolutionCover, cover, title, price }: any) => {
  return (
    <div className={cn(styles.productItem, { [styles.productItemActive]: isActive })} onClick={handleClick}>
      <div className={styles.productItemCover}>
        <img src={highResolutionCover ? highResolutionCover : `data:image/jpeg;base64,${cover}`} alt="" />
      </div>
      <div className={styles.productItemTitleContainer}>
        <h3 className={styles.productItemTitle}>{title}</h3>
        {price && <p className={styles.productItemPrice}>${price}</p>}
      </div>
    </div>
  );
};

export default ProductItem;
