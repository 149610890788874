import React, { useLayoutEffect } from 'react';

const DigitalSignatureCanvas = ({ signature = 'Roman Malyshev Sergeevich Roman', sigPad }) => {
  const resizeText = (txt, maxWidth, fontSize, context) => {
    context.font = `${fontSize}px cursive`;
    const minFontSize = 10;
    let width = context.measureText(txt).width;
    if (width > maxWidth) {
      let newfontSize = fontSize;
      let decrement = 1;
      let newWidth;
      while (width > maxWidth) {
        newfontSize -= decrement;
        if (newfontSize < minFontSize) {
          return minFontSize;
        }
        context.font = `${newfontSize}px cursive`;
        newWidth = context.measureText(txt).width;
        if (newWidth < maxWidth && decrement === 1) {
          decrement = 0.1;
          newfontSize += 1;
        } else {
          width = newWidth;
        }
      }
      return newfontSize;
    } else {
      return fontSize;
    }
  };

  const getFontSize = context => {
    const ratio = 70 / 500;
    const size = context.canvas.width * ratio;
    return size | 0;
  };

  const renderSignature = ({ context, canvas }) => {
    const text = signature;
    const fontSize = resizeText(text, 500, getFontSize(context), context);
    context.font = `${fontSize}px cursive`;
    context.fillStyle = 'rgb(255,255,255)';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = 'rgb(0,0,0)';
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.fillText(text, 500 / 2, 200 / 2);
  };

  useLayoutEffect(() => {
    const canvas = sigPad?.current;
    const context = canvas.getContext('2d');
    // context.fillStyle = 'rgb(0,0,255)';
    // context.fillRect(0, 0, 100, 100);
    if (document) {
      document.fonts.load('10pt "cursive"').then(() => renderSignature({ context, canvas }));
    }
    return () => context.clearRect(0, 0, canvas.width, canvas.height);
  }, [signature]);

  return (
    <canvas
      width={500}
      height={200}
      ref={sigPad}
      style={{
        width: 500,
        height: 200,
      }}
    />
  );
};

export default DigitalSignatureCanvas;
