import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  GET_PRODUCT_CATEGORIES_TYPES,
  getProductCategoriesActionSuccess,
  getProductCategoriesActionFailure,
} from '../actions';
import * as Api from '../../../api';
import { isDefaultMode, isFoodMode, isFoodAndActivitiesMode } from '../../../utils/detectTerminalMode';
import { ProductCategoriesScreenModes } from '../../../typings/product';

export function* getProductCategoriesWorker(action) {
  try {
    let categoriesList = [];
    const { categories, mode } = action.payload;

    if (
      isDefaultMode(mode) ||
      (isFoodAndActivitiesMode(mode) && categories === ProductCategoriesScreenModes.ACTIVITY)
    ) {
      const { data: activitiesCategories } = yield call(Api.fetchActivitiesCategories);
      categoriesList = [...categoriesList, ...activitiesCategories];
    }

    if (isFoodMode(mode) || (isFoodAndActivitiesMode(mode) && categories === ProductCategoriesScreenModes.FOOD)) {
      const { data: foodCategoriesResponse } = yield call(Api.fetchFoodCategories);

      const foodCategories = foodCategoriesResponse.map(item => ({
        ...item,
        productType: 'food',
      }));

      categoriesList = [...categoriesList, ...foodCategories];
    }

    yield put<any>(getProductCategoriesActionSuccess({ categoriesList }));
  } catch (e) {
    yield put<any>(getProductCategoriesActionFailure());
  }
}

export default function* getProductCategoriesSaga() {
  yield all([yield takeLatest(GET_PRODUCT_CATEGORIES_TYPES.REQUEST, getProductCategoriesWorker)]);
}
