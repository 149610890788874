const CHECK_IN_TYPES = {
  onlineBooking: {
    label: 'Enable Online Booking Check-In?',
    value: 'onlineBooking',
  },
  event: {
    label: 'Enable Event Check-In?',
    value: 'event',
  },
  eventList: {
    label: 'Enable Event List Check-In?',
    value: 'eventList',
  },
  maximumSelection: {
    label: 'Enable Maximum Selection for Activities?',
    value: 'maximumSelection',
  },
};

export default CHECK_IN_TYPES;
