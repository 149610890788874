import { TERMINAL_MODES } from '../constants';

export const isDefaultMode = (currentMode: string | null | undefined) => {
  return TERMINAL_MODES.default.value === currentMode;
};

export const isFoodMode = (currentMode: string | null | undefined) => {
  return TERMINAL_MODES.food.value === currentMode;
};

export const isFoodAndActivitiesMode = (currentMode: string | null | undefined) => {
  return TERMINAL_MODES.foodAndActivities.value === currentMode;
};

export const isRegistrationMode = (currentMode: string | null | undefined) => {
  return TERMINAL_MODES.registration.value === currentMode;
};

export const isCheckInMode = (currentMode: string | null | undefined) => {
  return TERMINAL_MODES.reservationCheckIn.value === currentMode;
};
