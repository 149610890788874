export const LOG_IN_ANOTHER_WAY_TYPES = {
  ADD_PHONE_AND_PIN: 'logInAntherWay/ADD_PHONE_AND_PIN',
  ADD_PIN: 'logInAntherWay/ADD_PIN',
  ENTER_PIN: 'logInAntherWay/ENTER_PIN',

  UPDATE_PHONE_REQUEST: 'logInAntherWay/UPDATE_PHONE_REQUEST',
  UPDATE_PHONE_SUCCESS: 'logInAntherWay/UPDATE_PHONE_SUCCESS',
  UPDATE_PHONE_FAILURE: 'logInAntherWay/UPDATE_PHONE_FAILURE',

  UPDATE_PIN_REQUEST: 'logInAntherWay/UPDATE_PIN_REQUEST',
  UPDATE_PIN_SUCCESS: 'logInAntherWay/UPDATE_PIN_SUCCESS',
  UPDATE_PIN_FAILURE: 'logInAntherWay/UPDATE_PIN_FAILURE',

  UPDATE_BIRTH_DATE: 'logInAntherWay/UPDATE_BIRTH_DATE',

};

export const logInAntherWayAddPhoneAndPinAction = (payload: any) => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.ADD_PHONE_AND_PIN,
  payload,
});

export const logInAntherWayAddPinAction = (payload: any) => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.ADD_PIN,
  payload,
});

export const logInAntherWayEnterPinAction = (payload: any) => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.ENTER_PIN,
  payload,
});

export const logInAntherWayUpdatePhoneAction = (payload: any) => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PHONE_REQUEST,
  payload,
});

export const logInAntherWayUpdatePhoneSuccess = (payload: any) => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PHONE_SUCCESS,
  payload,
});

export const logInAntherWayUpdatePhoneFailure = () => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PHONE_FAILURE,
});

export const logInAntherWayUpdatePinAction = (payload: any) => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PIN_REQUEST,
  payload,
});

export const logInAntherWayUpdatePinSuccess = (payload: any) => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PIN_SUCCESS,
  payload,
});

export const logInAntherWayUpdatePinFailure = () => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.UPDATE_PIN_FAILURE,
});

export const updateCustomerBirthDate = (payload: string) => ({
  type: LOG_IN_ANOTHER_WAY_TYPES.UPDATE_BIRTH_DATE,
  payload
});
