import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import HeaderPanel from '../../components/HeaderPanel';
import { getCustomerGroupAction } from '../../store/CustomerGroup/actions';
import AddGuestForm from '../../components/AddGuestForm';

import iconUser from '../../assets/icons/user.svg';
import iconTicket from '../../assets/icons/ticket.svg';
import iconGear from '../../assets/icons/gear.svg';

import styles from './styles.module.scss';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import NavigationButton from '../../components/NavigationButton';
import UpdateBirthDate from '../../components/UpdateBirthDate/UpdateBirthDate';
import { createCartAction } from '../../store/Cart/actions';
import { getConvenienceFees } from '../../store/Taxes/actions';

const MainFoodModeScreen = () => {
  const dispatch = useDispatch();
  const {
    customerStore,
    cartStore: { loading },
    terminalSettings: { checkInType },
    confirmationCodeReducer,
  } = useSelector(state => state);

  const [showAddGuest, setShowAddGuest] = React.useState(false);

  const handleHomeClick = () => {
    setShowAddGuest(false);
  };

  React.useEffect(() => {
    dispatch(getCustomerGroupAction({ custId: customerStore.custId }));
  }, []);

  const startPurchaseFlow = () => {
    dispatch(createCartAction());
    dispatch(getConvenienceFees());
  };

  const showAddGuestFn = () => {
    setShowAddGuest(true);
  };

  return (
    <>
      <ContentContainer loading={customerStore.loading || loading} theme="red">
        {customerStore &&
        checkInType.onlineBooking &&
        confirmationCodeReducer?.confirmationNumber &&
        confirmationCodeReducer?.data?.phoneNumber === customerStore.phoneNumber &&
        new Date(customerStore.birthDate).getFullYear() < 1800 ? (
          <UpdateBirthDate />
        ) : (
          <>
            <HeaderPanel theme="dark" shadow logOutButton />
            <div className={styles.content}>
              <div className={styles.greeting}>
                <h1 className={styles.title}>Welcome</h1>
                <h2 className={styles.userName}>
                  {customerStore.fName} {customerStore.lName}!
                </h2>
              </div>
              <div className={styles.line} />

              <div className={styles.navigation}>
                <NavigationButton
                  icon={iconTicket}
                  customClass={styles.navigationItem2}
                  label={'Purchase Items'}
                  onClick={startPurchaseFlow}
                />

                <NavigationButton
                  customClass={styles.navigationItem}
                  icon={iconUser}
                  onClick={showAddGuestFn}
                  label={'Add Guest'}
                />

                <NavigationButton
                  customClass={styles.navigationItem}
                  icon={iconGear}
                  label={'Manage Accounts'}
                  onClick={() => history.push(ROUTES.PROFILE)}
                />
              </div>
            </div>
          </>
        )}
        {showAddGuest && (
          <AddGuestForm handleHomeClick={handleHomeClick} endAddingGuestProcessHandler={() => setShowAddGuest(false)} />
        )}
      </ContentContainer>

      <IdleMonitor />
    </>
  );
};

export default MainFoodModeScreen;
