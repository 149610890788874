export const getConvenienceFee = ({ fees, amount }: any) => {
  const {
    ClubKioskEnableClubSpeedConvenienceFee,
    ClubKioskClubSpeedConvenienceFeeType,
    ClubKioskClubSpeedConvenienceFeeAmount,
    ClubKioskClubSpeedConvenienceFeeMinimum,
    ClubKioskClubSpeedConvenienceFeeMaximum,
  } = fees;

  const feeAmount = 0.0;

  if (!ClubKioskEnableClubSpeedConvenienceFee || !amount) {
    return feeAmount;
  }

  if (ClubKioskEnableClubSpeedConvenienceFee === "true") {
    if (ClubKioskClubSpeedConvenienceFeeType === "1") {
      // if (ClubKioskClubSpeedConvenienceFeeAmount >= ClubKioskClubSpeedConvenienceFeeMaximum) {
      //   return Number(ClubKioskClubSpeedConvenienceFeeMaximum);
      // }

      // if (ClubKioskClubSpeedConvenienceFeeAmount <= ClubKioskClubSpeedConvenienceFeeMinimum) {
      //   return Number(ClubKioskClubSpeedConvenienceFeeMinimum);
      // }

      // if (
      //   ClubKioskClubSpeedConvenienceFeeAmount < ClubKioskClubSpeedConvenienceFeeMaximum &&
      //   ClubKioskClubSpeedConvenienceFeeAmount > ClubKioskClubSpeedConvenienceFeeMinimum
      // ) {
      //   return Number(ClubKioskClubSpeedConvenienceFeeAmount);
      // }
      return Number(ClubKioskClubSpeedConvenienceFeeAmount);
    } else {
      const percentAmount = (ClubKioskClubSpeedConvenienceFeeAmount / 100) * amount;
      if (percentAmount >= ClubKioskClubSpeedConvenienceFeeMaximum) {
        return Number(ClubKioskClubSpeedConvenienceFeeMaximum);
      }

      if (percentAmount <= ClubKioskClubSpeedConvenienceFeeMinimum) {
        return Number(ClubKioskClubSpeedConvenienceFeeMinimum);
      }

      if (
        percentAmount < ClubKioskClubSpeedConvenienceFeeMaximum &&
        percentAmount > ClubKioskClubSpeedConvenienceFeeMinimum
      ) {
        return Number(percentAmount);
      }
    }
  }

  return feeAmount;
};
