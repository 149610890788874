import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../App';
import IdleMonitor from '../../services/IdleMonitor';
import { startResetPinAction } from '../../store/Customer/actions';
import Logo from '../../components/Logo';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import TextField from '../../components/TextField';
import PhoneKeypad from '../../components/PhoneKeypad';
import arrowBackWhite from '../../assets/icons/arrow-back-white.svg';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

type formType = {
  [key: string]: string;
};

const UpdatePinScreen = () => {
  const dispatch = useDispatch();
  const { customerStore } = useSelector(state => state);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [activeField, setActiveField] = useState('pin');
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({
    pin: '',
    confirmPin: '',
  });
  const [pinErrorMessage, setPinErrorMessage] = useState('');
  const [confirmPinErrorMessage, setConfirmPinErrorMessage] = useState('');

  const goBack = () => {
    history.goBack();
  };

  const phoneKeypadHandler = (newValueOfField: string) => {
    setPinErrorMessage('');
    setConfirmPinErrorMessage('');
    setFieldsOfForm({ ...fieldsOfForm, [activeField]: newValueOfField });
  };

  const goToSignUp = () => {
    if (fieldsOfForm.pin.length < 4 || fieldsOfForm.pin.length > 4) {
      return setPinErrorMessage('Pin should be ...');
    }
    if (fieldsOfForm.pin !== fieldsOfForm.confirmPin) {
      return setConfirmPinErrorMessage('Hello');
    }
    dispatch(startResetPinAction({ pin: fieldsOfForm.pin }));
  };

  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <div className={styles.leftSideContent}>
            <Logo width="270" />
            <div className={styles.separator} />
            <h1 className={styles.title}>Self-Serve Kiosk</h1>
            <h3 className={styles.mainMessage}>Please create a new PIN</h3>
            <div className={styles.phoneInformation}>
              <button onClick={goBack} className={styles.phoneInformationBack}>
                <img src={arrowBackWhite} alt="" />
              </button>
              <div className={styles.phoneInformationNumber}>{customerStore.cell}</div>
            </div>
            <div className={styles.rowMargin}>
              <TextField
                readOnly={!nativeKeyboardState}
                type="password"
                value={fieldsOfForm.pin}
                placeholder="PIN"
                isActive={activeField === 'pin'}
                invalid={pinErrorMessage.length > 0}
                errorMessage={pinErrorMessage}
                theme="themeTransparent"
                onFocus={() => setActiveField('pin')}
              />
            </div>
            <div className={styles.rowMargin}>
              <TextField
                readOnly={!nativeKeyboardState}
                type="password"
                value={fieldsOfForm.confirmPin}
                placeholder="Confirm PIN"
                isActive={activeField === 'confirmPin'}
                invalid={confirmPinErrorMessage.length > 0}
                errorMessage={confirmPinErrorMessage}
                theme="themeTransparent"
                onFocus={() => setActiveField('confirmPin')}
              />
            </div>

            <Button handleClick={goToSignUp} theme="white" customClass={styles.buttonStyle}>
              Continue
            </Button>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div>
            <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler} />
          </div>
        </div>
      </div>
      <IdleMonitor />
    </Fragment>
  );
};

export default UpdatePinScreen;
