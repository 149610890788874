import {
  ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES,
  REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES,
  GET_CUSTOMER_GROUP_TYPES,
} from '../actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';
export const initialState = {
  members: [],
  loading: false,
};

export type InitialStateType = typeof initialState;

export default function customerGroupReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_MEMBER_TO_CUSTOMER_GROUP_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_MEMBER_FROM_CUSTOMER_GROUP_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_CUSTOMER_GROUP_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CUSTOMER_GROUP_TYPES.SUCCESS:
      return {
        ...state,
        members: payload,
        loading: false,
      };
    case GET_CUSTOMER_GROUP_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    case GET_CUSTOMER_GROUP_TYPES.RESET:
      return {
        ...state,
        members: [],
        loading: false,
      };
    default:
      return state;
  }
}
