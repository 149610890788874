import request from '../services/Api';
import { AxiosResponse } from 'axios';

export const fetchAllControlPanelSettings = (): Promise<AxiosResponse> =>
  request.get('CoreAPI/ControlPanelSettings/All');

export const fetchControlPanelSettings = (settingsName: string, terminalName: string): Promise<AxiosResponse> =>
  request.get(`CoreAPI/ControlPanelSettings?SettingName=${settingsName}&TerminalName=${terminalName}`);

export const updateControlPanelSettings = (data: any): Promise<AxiosResponse> =>
  request.put(`CoreAPI/ControlPanelSettings`, data);

export const checkKioskConfiguration = (data): Promise<AxiosResponse<any>> => {
  return request.post(`CoreAPI//SelfServeKiosk/CheckKioskConfiguration`, data);
};