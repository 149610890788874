import React, { useEffect, useState } from 'react';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../ContentContainer';
import HeaderPanel from '../HeaderPanel';
import WizardFormStep from '../WizardFormStep';
import moment from 'moment';
import * as Api from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomerBirthDate } from '../../store/Customer/actions';

const UpdateBirthDate = () => {
  const { customerStore } = useSelector(state => state);

  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const isDateValid = () => {
    const date = moment(value, 'MM/DD/YYYY');
    if (Number(date.year() >= 1900) && date.isValid()) {
      return true;
    }
    return false;
  };

  const handleNext = async () => {
    if (isDateValid()) {
      setError('');
      try {
        const res = await Api.updateCustomerBirthDate({
          birthDate: moment.utc(value, 'MM/DD/YYYY').toDate(),
          custId: customerStore.custId,
        });
        if (res?.status === 200) {
          dispatch(updateCustomerBirthDate(moment.utc(value, 'MM/DD/YYYY').toISOString()));
        }
      } catch (error) {
        setError('Something went wrong');
      }
    } else {
      setError('Please provide a correct date');
    }
  };

  return (
    <>
      <ContentContainer bgColor="#FFFFFF">
        <HeaderPanel startOverButton shadow />
        <WizardFormStep
          type="numeric"
          fieldName="birthDate"
          value={value}
          label="Update Date Of Birth MM/DD/YYYY"
          onChange={(_, val) => {
            setValue(val);
          }}
          required={true}
          placeholder={''}
          errorMessage={error}
          isActive={true}
          goToNextStep={handleNext}
          goToPrevStep={() => undefined}
        />
      </ContentContainer>
      {/* <WarningAlert
      isOpen={manyMatchesModal}
      title=""
      description="We've found more than one account with the information you've provided, please see a cashier for help"
      handleClick={startOver}
    /> */}
      <IdleMonitor />
    </>
  );
};

export default UpdateBirthDate;
