import { combineReducers } from 'redux';
import { terminalSettingsReducer, registrationSettingsReducer } from './Settings/reducers';
import { terminalStateReducer } from './Terminal/reducers';
import { sourceReducer } from './Source/reducers/';
import {
  customerReducer,
  resetPinReducer,
  newCustomerReducer,
  newGuestReducer,
  confirmationCodeReducer,
  eventsReducer,
  assignActivitiesReducer,
} from './Customer/reducers';
import { convenienceFeesReducer } from './Taxes/reducers';
import { customerGroupReducer } from './CustomerGroup/reducers';
import { customerRelationshipsReducer } from './CustomerRelationships/reducers';
import { productCategoriesReducer } from './ProductCategories/reducers';
import { productsByCategoryReducer } from './ProductsByCategory/reducers';
import { cartReducer, cartItemReducer } from './Cart/reducers';
import { paymentReducer, paymentCardConnectReducer } from './Payment/reducers';
import { payByLinkReducer } from './PayByLink/reducers';
import { purchaseFlowReducer } from './PurchaseFlow/reducers';
import { membershipReducer } from './Membership/reducers';

const rootReducer = combineReducers({
  terminalSettings: terminalSettingsReducer,
  terminal: terminalStateReducer,
  registrationSettings: registrationSettingsReducer,
  source: sourceReducer,
  customerStore: customerReducer,
  resetPinStore: resetPinReducer,
  newCustomerStore: newCustomerReducer,
  newGuestStore: newGuestReducer,
  customerGroup: customerGroupReducer,
  customerRelationships: customerRelationshipsReducer,
  productCategoriesStore: productCategoriesReducer,
  productsByCategoryStore: productsByCategoryReducer,
  cartStore: cartReducer,
  cartItemStore: cartItemReducer,
  Store: cartItemReducer,
  payment: paymentReducer,
  paymentCardConnectStore: paymentCardConnectReducer,
  payByLink: payByLinkReducer,
  purchaseFlowStore: purchaseFlowReducer,
  membershipStore: membershipReducer,
  confirmationCodeReducer: confirmationCodeReducer,
  events: eventsReducer,
  assignActivitiesReducer,
  convenienceFees: convenienceFeesReducer,
});

export default rootReducer;

declare module 'react-redux' {
  interface DefaultRootState extends ReturnType<typeof rootReducer> {}
}
