import { CONFIRMATION_CODE_TYPES } from '../actions';

export const initialState = null;

export type InitialStateType = typeof initialState;

export default function confirmationCodeReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case CONFIRMATION_CODE_TYPES.VERIFY_ACTIVITY_BY_CONFIRMATION_CODE:
    case CONFIRMATION_CODE_TYPES.VERIFY_CONFIRMATION_CODE_FOR_EVENT_AND_ACTIVITY:
      return {
        ...state,
        pending: true,
      };
    case CONFIRMATION_CODE_TYPES.VERIFY_EVENT_BY_CONFIRMATION_CODE:
      return {
        ...state,
        pending: true,
      };
    case CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_SUCCESS:
      return {
        ...state,
        ...payload,
        pending: false,
      };
    case CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_SUCCESSFUL_FOR_EVENT_AND_ACTIVITY:
      return {
        ...state,
        ...payload,
        pending: false,
      };
    case CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_FAILURE:
      return {
        ...state,
        success: false,
        pending: false,
        errorMessage: payload.error,
      };
    case CONFIRMATION_CODE_TYPES.CONFIRMATION_CODE_CHECK_RESET:
      return initialState;
    default:
      return state;
  }
}
