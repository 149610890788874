export const EVENTS = {
  GET_CURRENT_DAY_EVENTS: 'customer/GET_CURRENT_DAY_EVENTS',
  GET_CURRENT_DAY_EVENTS_SUCCESSFULLY: 'customer/GET_CURRENT_DAY_EVENTS_SUCCESSFULLY',
  GET_CURRENT_DAY_EVENTS_FAILURE: 'customer/GET_CURRENT_DAY_EVENTS_FAILURE',
  SELECT_EVENT: 'customer/SELECT_EVENT',
  RESET_EVENT: 'customer/RESET_EVENT',
  CHECK_IF_EVENT_EXIST: 'customer/CHECK_IF_EVENT_EXIST',
  ADD_GUEST_IN_EVENT: 'customer/ADD_GUEST_IN_EVENT',
};

export const getCurrentDayEvents = () => ({
  type: EVENTS.GET_CURRENT_DAY_EVENTS,
});

export const getEventsSuccessfully = payload => ({
  type: EVENTS.GET_CURRENT_DAY_EVENTS_SUCCESSFULLY,
  payload,
});

export const getEventsFailure = payload => ({
  type: EVENTS.GET_CURRENT_DAY_EVENTS_FAILURE,
  payload,
});

export const selectEvent = event => ({
  type: EVENTS.SELECT_EVENT,
  payload: event,
});

export const resetEvent = () => ({
  type: EVENTS.RESET_EVENT,
});

export const checkIfEventExistsAction = (payload: string) => ({
  type: EVENTS.CHECK_IF_EVENT_EXIST,
  payload,
});

export const addGuestInEventAction = (payload: { eventId: string; custId: string }) => ({
  type: EVENTS.ADD_GUEST_IN_EVENT,
  payload,
});
