import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  UPDATE_TERMINAL_SETTINGS_TYPES,
  updateTerminalSettingsActionSuccess,
  updateTerminalSettingsActionFailure,
  SELECT_MODE_TYPE,
  CHECK_KIOSK_CONFIGURATION,
} from '../actions';
import { ROUTES, PAYMENT_PROCESSORS } from '../../../constants';
import { history } from '../../../App';
import * as Api from '../../../api';

export function* updateTerminalSettingsWorker({ payload }: any) {
  try {
    const settings = {
      ...payload,
      mode: payload.mode.value,
      paymentProcessor: payload.paymentProcessor?.value,
    };

    if (settings.paymentProcessor === PAYMENT_PROCESSORS.cardConnect.value) {
      const { data: controlPanelSettingsOnline } = yield call(
        Api.fetchControlPanelSettings,
        'CardConnectURL',
        'MainEngine',
      );
      settings.cardConnectURL = controlPanelSettingsOnline[0].settingValue;
    }

    if (settings.paymentProcessor === PAYMENT_PROCESSORS.clover.value) {
      const { data: controlPanelSettingsClover } = yield call(
        Api.fetchControlPanelSettings,
        'ExternalPaymentTerminalHost',
        `SSK${settings.terminalNumber}`,
      );

      yield call(Api.updateControlPanelSettings, {
        settingName: controlPanelSettingsClover[0].settingName,
        settingValue: settings.ip,
        terminalName: `SSK${settings.terminalNumber}`,
      });
      settings.cardConnectURL = null;
    }

    yield put<any>(updateTerminalSettingsActionSuccess(settings));
    yield call<any>(history.replace, ROUTES.ROOT);
  } catch (err) {
    console.log(err);
    yield put<any>(updateTerminalSettingsActionFailure());
  }
}

export function* selectModeWorker({ payload }: any) {
  if (payload === 'only_food') {
    history.replace(ROUTES.PRODUCTS);
  } else if (payload === 'food_&_activities') {
    history.replace(ROUTES.LOG_IN_FIRST_STEP);
  }
}

type Props = {
  payload: {
    kioskMode: string;
    terminalName: number;
    isEnabledOnlineBookingCheckin: boolean;
    isEnabledEventCheckIn: boolean;
    isEnabledEventListCkeckIn: boolean;
  };
  type: string;
};

export function* checkKioskConfigurationSaga({ payload }: Props) {
  try {
    const { data }: { data: any } = yield call(Api.checkKioskConfiguration, {
      ...payload,
    });

    yield put(
      updateTerminalSettingsActionSuccess({
        checkInType: {
          onlineBooking: payload.isEnabledOnlineBookingCheckin,
          event: payload.isEnabledEventCheckIn,
          eventList: data.data.isEnabledEventListCkeckIn,
        },
      }),
    );
  } catch (err) {
    yield put(updateTerminalSettingsActionFailure());
  }
}

export default function* startPaymentProcessSaga() {
  yield all([
    yield takeLatest(UPDATE_TERMINAL_SETTINGS_TYPES.REQUEST, updateTerminalSettingsWorker),
    yield takeLatest(SELECT_MODE_TYPE, selectModeWorker),
    yield takeLatest(CHECK_KIOSK_CONFIGURATION.REQUEST, checkKioskConfigurationSaga),
  ]);
}
