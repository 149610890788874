import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import styles from './styles.module.scss';
import { terminalModeStateSelector, terminalCheckInTypeStateSelector } from '../../store/Settings/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import IdleMonitor from '../../services/IdleMonitor';
import Button from '../../components/Button';
import cn from 'classnames';
import { history } from '../../App';
import {
  confirmationCodeCheckReset,
} from '../../store/Customer/actions/confirmationCodeActions';
import { ConfirmationCodeCheckResponse } from '../../store/Customer/types/confirmationCode';
import checkInIcon from '../../assets/icons/check-in-red.svg';
import { startCustomerSessionAction } from '../../store/Terminal/actions';
import { ROUTES } from '../../constants';

const IWantFunConfirmationScreen = () => {
  const [step, setStep] = useState(1);
  const terminalMode = useSelector(terminalModeStateSelector);
  const checkInType = useSelector(terminalCheckInTypeStateSelector);

  const confirmationCodeData = useSelector(state => state.confirmationCodeReducer) as ConfirmationCodeCheckResponse;
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const noHandleClick = () => {
    if ((checkInType['onlineBooking'] || checkInType['event']) && checkInType['eventList']) {
      history.replace(ROUTES.EVENT_LISTS);
      return;
    }

    dispatch(startCustomerSessionAction(terminalMode));
  };

  useEffect(() => {
    if (confirmationCodeData && !confirmationCodeData.success && confirmationCodeData.message) {
      setError('Confirmation number not found');
    }
    if (confirmationCodeData && confirmationCodeData.success) {
      dispatch(startCustomerSessionAction(terminalMode));
    }
  }, [confirmationCodeData]);

  useEffect(() => {
    if (step === 1) {
      dispatch(confirmationCodeCheckReset());
      setError('');
    }
  }, [step]);

  return (
    <>
      <ContentContainer>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div className={styles.confirmation}>
              <h1>Do You Have a Confirmation Code?</h1>
              <div className={styles.imageWrapper}>
                <img src={checkInIcon} alt="" />
              </div>
              <p>The Confirmation Code is located in your confirmation or event email.</p>
              <div>
                <Button customClass={styles.btn} handleClick={() => noHandleClick()}>
                  NO
                </Button>
                <Button
                  theme="red"
                  customClass={styles.btn}
                  handleClick={() => history.push(ROUTES.RESERVATION_CHECK_IN)}
                >
                  YES
                </Button>
              </div>
            </div>
          </div>
        </div>
        <IdleMonitor />
      </ContentContainer>
    </>
  );
};

export default IWantFunConfirmationScreen;
