import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  START_CUSTOMER_SESSION,
  START_CUSTOMER_SESSION_FOR_FUN,
  START_CUSTOMER_SESSION_FOR_RESERVATION_CHECK_IN,
} from '../actions';
import { ROUTES, TERMINAL_MODES, CHECK_IN_TYPES } from '../../../constants';
import { history } from '../../../App';

export function* startCustomerSessionWorker({ payload }: any) {
  const sessionMode = payload;

  switch (sessionMode) {
    case TERMINAL_MODES.default.value:
      history.replace(ROUTES.LOG_IN_FIRST_STEP);
      break;
    case TERMINAL_MODES.food.value:
      history.replace(ROUTES.PRODUCTS);
      break;
    case TERMINAL_MODES.foodAndActivities.value:
      history.replace(ROUTES.LOG_IN_FIRST_STEP);
      break;
    case TERMINAL_MODES.registration.value:
      history.replace(ROUTES.LOG_IN_FIRST_STEP);
      break;
    case TERMINAL_MODES.reservationCheckIn.value:
      history.replace(ROUTES.RESERVATION_CHECK_IN);
      break;
    default:
  }
}

export function* startCustomerSessionForFunWorker({ payload }: any) {
  const sessionMode = payload.mode;
  const sessionCheckInType = payload.checkInType;

  if (sessionMode === TERMINAL_MODES.default.value || sessionMode === TERMINAL_MODES.foodAndActivities.value) {
    if ((sessionCheckInType.onlineBooking || sessionCheckInType.event) && sessionCheckInType.eventList) {
      history.replace(ROUTES.CONFIRMATION_CODE);
      return;
    }

    if (sessionCheckInType.onlineBooking || sessionCheckInType.event) {
      history.replace(ROUTES.CONFIRMATION_CODE);
      return;
    }

    if (sessionCheckInType.eventList) {
      history.replace(ROUTES.EVENT_LISTS);
      return;
    }

    // Default
    history.replace(ROUTES.LOG_IN_FIRST_STEP);
  }
}

export function* startCustomerSessionForReservationCheckInWorker({ payload }: any) {
  const sessionCheckInType = payload;

  // For only Check-in mode
  if ((sessionCheckInType.onlineBooking || sessionCheckInType.event) && sessionCheckInType.eventList) {
    history.replace(ROUTES.EVENT_LISTS);
    return;
  }

  if (sessionCheckInType.eventList) {
    history.replace(ROUTES.EVENT_LISTS);
    return;
  }

  if (sessionCheckInType.onlineBooking || sessionCheckInType.event) {
    history.replace(ROUTES.RESERVATION_CHECK_IN);
    return;
  }

  history.replace(ROUTES.RESERVATION_CHECK_IN);
}

export default function* startPaymentProcessSaga() {
  yield all([
    yield takeLatest(START_CUSTOMER_SESSION, startCustomerSessionWorker),
    yield takeLatest(START_CUSTOMER_SESSION_FOR_FUN, startCustomerSessionForFunWorker),
    yield takeLatest(START_CUSTOMER_SESSION_FOR_RESERVATION_CHECK_IN, startCustomerSessionForReservationCheckInWorker),
  ]);
}
