import React from 'react';
import { ActivityGuestType } from '../../../store/Customer/types/assignActivities';
import Button from '../../Button';
import Modal from '../../Modal';
import styles from './styles.module.scss';

const WaiverWarningModal = ({ open, toggle, showWaiverScreen, data }: PropsTypes) => {
  return (
    <Modal isOpen={open}>
      <div className={styles.container}>
        <h2 className={styles.title}>Incomplete Waiver </h2>
        <div className={styles.description}>
          <p>The following guests do not have the required waiver:</p>
          <ul>
            {data.map(el => (
              <li key={el.custId}>{`${el.fName} ${el.lName}`}</li>
            ))}
          </ul>
          <p>Continue to complete the waiver or return to change your selection.</p>
        </div>
        <div className={styles.footer}>
          <Button customClass={styles.button} handleClick={toggle}>
            return
          </Button>
          <Button
            theme="red"
            customClass={styles.button}
            handleClick={() => {
              toggle();
              showWaiverScreen();
            }}
          >
            continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WaiverWarningModal;

interface PropsTypes {
  open: boolean;
  toggle: () => void;
  data: ActivityGuestType[];
  showWaiverScreen: () => void;
}
