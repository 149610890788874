import { END_CUSTOMER_SESSION } from '../../General/actions';
import { CONVENIENCE_FEES } from '../actions';

export const initialState = {
  ClubKioskEnableClubSpeedConvenienceFee: false,
  ClubKioskClubSpeedConvenienceFeeType: 1,
  ClubKioskClubSpeedConvenienceFeeAmount: 3,
  ClubKioskClubSpeedConvenienceFeeMinimum: 3,
  ClubKioskClubSpeedConvenienceFeeMaximum: 3,
};

export type InitialStateType = typeof initialState;

export default function taxesReducer(state = initialState, { type, payload }: any): InitialStateType {
  switch (type) {
    case CONVENIENCE_FEES.REQUEST:
      return {
        ...state,
      };
    case CONVENIENCE_FEES.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case CONVENIENCE_FEES.FAILURE:
      return {
        ...initialState,
      };
    case END_CUSTOMER_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
