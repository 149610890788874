import request from '../services/Api';
import { AxiosResponse } from 'axios';

export const fetchAllSettings = (): Promise<AxiosResponse> => request.get('CoreAPI/controlPanelSettings/All');

export const fetchKioskVersion = (): Promise<AxiosResponse> => request.get('CoreAPI/Version');

export const fetchKioskLicense = (): Promise<AxiosResponse> =>
  request.get('CoreAPI/SelfServeKiosk/SelfServeKioskLicense');

export const fetchTaxes = (): Promise<AxiosResponse> => request.get('CoreAPI/Taxes');
