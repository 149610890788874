import { Modes } from '../typings/terminalSettings';

interface TerminalModes {
  [index: string]: Modes;
}

const TERMINAL_MODES: TerminalModes = {
  default: {
    label: 'Default',
    value: 'default',
  },
  food: {
    label: 'Only Food',
    value: 'food',
  },
  foodAndActivities: {
    label: 'Food & Activities',
    value: 'foodAndActivities',
  },
  registration: {
    label: 'Registration Mode',
    value: 'registration',
  },
  reservationCheckIn: {
    label: 'Reservation Check-In',
    value: 'reservationCheckIn',
  },
};

export default TERMINAL_MODES;
